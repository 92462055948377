import React, { useContext } from 'react';
import { Redirect } from 'react-router-dom';
import { GlobalContext } from '../../../context/GlobalContext';
import styles from '../../../assets/styles/subscriptions.module.scss';
import SubscriptionDetails from '../components/SubscriptionDetails';
import { roles } from '../../../constants';
import { showSubscriptionPage } from '../../payment/Payment.utils';
import { FeatureFlag } from 'controllers/_exports';
import SubscriptionDetailsOld from '../componentsOld/SubscriptionDetails';

const Subscription = () => {
  const selectedOrg = JSON.parse(localStorage.getItem('userInfo'))?.selectedOrg;
  const { state: globalState } = useContext(GlobalContext);

  return showSubscriptionPage({
    role: globalState?.role?.slug || roles().find(({ id }) => id === selectedOrg?.user_role)?.slug,
  }) ? (
    <div className={styles.subscriptionsPage}>
      {FeatureFlag.enabled('EDIT_SUBSCRIPTION') ? (
        <SubscriptionDetails />
      ) : (
        <SubscriptionDetailsOld />
      )}
    </div>
  ) : (
    <Redirect to="/" />
  );
};

export default Subscription;
