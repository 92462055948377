import React, { useEffect } from 'react';
import { Grid } from '@material-ui/core';
import OverallDetailItem from './OverallDetailItem';
import { imageBasePath } from 'constants';
import { tooltipTexts } from 'constants';
import { FeatureFlag } from 'controllers/_exports';
import {
  useGetHomeOnboardingCompletionQuery,
  useGetHomeTrainingCompletionQuery,
  useGetHomeRoleplayCompletionQuery,
  useGetHomeRampTimeQuery,
} from '../Home.queries';
import { useHomeStore } from '../Home.stores';
import Shimmer from './Shimmer';
import Organization from 'infrastructure/Organization';
const HomeCardStatistics = ({ overallDetails }) => {
  const { selectedDateFilter } = useHomeStore();
  const { data: onboardingCompletion = {}, isLoading: isLoadingOnboarding } =
    useGetHomeOnboardingCompletionQuery({
      time: Organization.FiltersEnabled(selectedDateFilter.slug),
    });
  const { data: trainingCompletion = {}, isLoading: isLoadingTraining } =
    useGetHomeTrainingCompletionQuery({
      time: Organization.FiltersEnabled(selectedDateFilter.slug),
    });
  const { data: roleplayCompletion = {}, isLoading: isLoadingRoleplay } =
    useGetHomeRoleplayCompletionQuery({
      time: Organization.FiltersEnabled(selectedDateFilter.slug),
    });
  const { data: rampTime = {}, isLoading: isLoadingRampTime } = useGetHomeRampTimeQuery({
    time: Organization.FiltersEnabled(selectedDateFilter.slug),
  });
  const newStatistics = FeatureFlag.enabled('NEW_HOME_STATISTICS');
  return (
    <>
      <Grid item xs={12} md={6} lg={3}>
        {newStatistics ? (
          isLoadingOnboarding ? (
            <Shimmer type="OverallDetailItem" />
          ) : (
            <OverallDetailItem
              image={`${imageBasePath}onboard_completion_icon.svg`}
              value={`${onboardingCompletion?.onboarding_completion_rate || 0}%`}
              title={`Onboarding Completion`}
              description={tooltipTexts.onboarding_completion}
            />
          )
        ) : (
          <OverallDetailItem
            image={`${imageBasePath}contract_value_icon.svg`}
            value={overallDetails.average_contract_value}
            title={`ACV`}
            unit={`dollar`}
            description={tooltipTexts.average}
          />
        )}
      </Grid>
      <Grid item xs={12} md={6} lg={3}>
        {isLoadingTraining ? (
          <Shimmer type="OverallDetailItem" />
        ) : (
          <OverallDetailItem
            image={`${imageBasePath}compeleion_rate_icon.svg`}
            value={`${trainingCompletion?.training_completion_rate}%`}
            title={`Training Completion`}
            description={tooltipTexts.training}
          />
        )}
      </Grid>
      <Grid item xs={12} md={6} lg={3}>
        {newStatistics ? (
          isLoadingRoleplay ? (
            <Shimmer type="OverallDetailItem" />
          ) : (
            <OverallDetailItem
              image={`${imageBasePath}roleplay_completion_icon.svg`}
              value={`${roleplayCompletion?.average_roleplays_completion_value || 0}%`}
              title={`Roleplay Completion`}
              description={tooltipTexts.roleplay_completion}
            />
          )
        ) : (
          <OverallDetailItem
            image={`${imageBasePath}win_rate_icon.svg`}
            value={
              overallDetails.customer_win_rate
                ? `${Math.round(overallDetails.customer_win_rate)}%`
                : overallDetails.customer_win_rate
            }
            title={`Win rate`}
            description={tooltipTexts.winrate}
          />
        )}
      </Grid>
      <Grid item xs={12} md={6} lg={3}>
        {isLoadingRampTime ? (
          <Shimmer type="OverallDetailItem" />
        ) : (
          <OverallDetailItem
            image={`${imageBasePath}ramp_time_icon.svg`}
            value={rampTime.ramp_time}
            title={`Ramp time`}
            description={tooltipTexts.ramp_time}
          />
        )}
      </Grid>
    </>
  );
};

export default HomeCardStatistics;
