import React, { useMemo } from 'react';
import { useRoleplayStore } from 'features/roleplay/Roleplay.store';
import { InputSelect } from 'shared_ui_components';
import { useGetListRoleplaysQuery } from 'features/roleplay/Roleplay.queries';
import DateTime from 'infrastructure/Datetime';
import { FeatureFlag } from 'controllers/_exports';
import Organization from 'infrastructure/Organization';
import classes from './AssignRoleplay.module.scss';
import { components } from 'react-select';

const customOption = {
  label: 'Create a Custom Roleplay',
  value: -1,
  description: '',
  scenarios: [],
  rating_criteria: [],
};

const RoleplayDropdownList = () => {
  const { data: roleplaysList = [] } = useGetListRoleplaysQuery();
  const isRep = Organization.isRep();
  const formDataAssignRoleplay = useRoleplayStore((state) => state?.formDataAssignRoleplay);
  const setFormDataAssignRoleplay = useRoleplayStore((state) => state?.setFormDataAssignRoleplay);
  const setScenariosList = useRoleplayStore((state) => state?.setScenariosList);
  const setFormDataCreateRoleplay = useRoleplayStore((state) => state?.setFormDataCreateRoleplay);

  const isCreating = formDataAssignRoleplay?.roleplay?.value === -1;
  const improvements = FeatureFlag.enabled('CUSTOM_ROLEPLAY_IMPROVEMENTS');

  const options = useMemo(() => {
    if (!roleplaysList.length > 0) return [];
    const aux = roleplaysList.map((roleplay) => {
      return {
        label: roleplay?.name,
        value: roleplay?.uuid,
        description: roleplay?.description,
        scenarios: roleplay?.scenarios,
        rating_criteria: roleplay?.rating_criteria,
        is_custom: roleplay?.is_custom,
      };
    });
    if (!FeatureFlag.enabled('CUSTOM_ROLEPLAY') || isRep) return aux;
    if (improvements) return aux;
    return [...aux, customOption];
  }, [isRep, roleplaysList, improvements]);

  const handleChange = (value) => {
    delete formDataAssignRoleplay.roleplay;
    setFormDataAssignRoleplay({ roleplay: value, ...formDataAssignRoleplay });
    setScenariosList(
      value?.scenarios.map((scenario) => {
        return { ...scenario, isSelected: false };
      })
    );
    setFormDataCreateRoleplay({
      scenarios: [{ name: '', id: 1 }],
      rating_criterials: [{ name: '', id: 1 }],
      deadline: DateTime.formatDate(new Date()) + ' 00:00:00',
    });
  };

  return (
    <>
      {improvements && (
        <p className="heading5" style={{ marginBottom: '0.5rem' }}>
          {isCreating ? 'Create a Custom Roleplay' : 'Select Roleplay'}
        </p>
      )}
      {!isCreating && (
        <InputSelect
          label={
            <div className={classes.selectorContainer}>
              <span>Roleplay</span>
              {improvements && !isCreating && (
                <button onClick={() => handleChange(customOption)}>+ Add custom roleplay</button>
              )}
            </div>
          }
          placeholder="Select roleplay"
          onChange={(value) => handleChange(value)}
          options={options}
          value={formDataAssignRoleplay?.roleplay}
          closeMenuOnSelect={true}
          noOptionsMessage={() => 'No matches found'}
          components={{ Option: CustomOption }}
        />
      )}
    </>
  );
};

const CustomOption = ({ innerRef, innerProps, ...rest }) => {
  return (
    <div ref={innerRef} {...innerProps}>
      <components.Option {...rest}>
        <div className={classes.customRoleplayOpt}>
          <p>{rest?.data?.label}</p>
          {FeatureFlag.enabled('CUSTOM_ROLEPLAY_IMPROVEMENTS') && rest?.data?.is_custom && (
            <span>Custom Roleplay</span>
          )}
        </div>
      </components.Option>
    </div>
  );
};

export default RoleplayDropdownList;
