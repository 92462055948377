import { useQuery, useMutation, useQueryClient, useInfiniteQuery } from '@tanstack/react-query';
import {
  getPostCategories,
  getHomeDetails,
  getPostsService,
  getListComments,
  createComment,
  createPost,
  getUsersService,
  createReactionCheer,
  createReactionLike,
  createReactionLove,
  getFeaturedPostsService,
  deletePost,
  createReactionLoveComment,
  createReactionLikeComment,
  createReactionCheerComment,
  createFeaturedPost,
  getFeaturedPostCount,
  getURLMetadataService,
} from './Stream.services';

import { toastFlashMessage } from '../../utils';

export const streamCacheKeys = {
  categories: 'stream_categories',
  POSTS: 'stream_posts',
  commentsOnAPost: 'stream_post_comments',
  homeDetails: 'home_details',
  USERS: 'stream_users',
  featuredPosts: 'featured_posts',
  featuredPostsCount: 'featured_posts_count',
};

export const useGetUsersQuery = ({ enabled = true }) =>
  useQuery([streamCacheKeys.USERS], getUsersService, { enabled, staleTime: 1000 * 60 * 5 });

export const useGetPostCategoriesQuery = () =>
  useQuery([streamCacheKeys.categories], () => getPostCategories());

export const useGetFeaturedPostsQuery = () =>
  useQuery([streamCacheKeys.featuredPosts], () => getFeaturedPostsService());

export const useGetHomeDetails = ({ time = 'all-time' }) => {
  return useQuery({
    queryKey: [streamCacheKeys.homeDetails, time],
    queryFn: () => getHomeDetails({ time }),
    refetchInterval: 0,
  });
};

export const useGetCommentsOnAPost = ({ post_id, enabled }) =>
  useQuery([streamCacheKeys.commentsOnAPost, post_id], () => getListComments({ post_id }), {
    enabled,
  });

export const useCreateReactionCheer = () => {
  const queryClient = useQueryClient();
  return useMutation((postId) => createReactionCheer(postId), {
    onSuccess: () => {
      queryClient.invalidateQueries([streamCacheKeys.POSTS]);
      queryClient.invalidateQueries([streamCacheKeys.featuredPosts]);
    },
  });
};

export const useCreateReactionLike = () => {
  const queryClient = useQueryClient();
  return useMutation((postId) => createReactionLike(postId), {
    onSuccess: () => {
      queryClient.invalidateQueries([streamCacheKeys.POSTS]);
      queryClient.invalidateQueries([streamCacheKeys.featuredPosts]);
    },
  });
};

export const useCreateReactionLove = () => {
  const queryClient = useQueryClient();
  return useMutation((postId) => createReactionLove(postId), {
    onSuccess: () => {
      queryClient.invalidateQueries([streamCacheKeys.POSTS]);
      queryClient.invalidateQueries([streamCacheKeys.featuredPosts]);
    },
  });
};

export const useCreateComment = () => {
  const queryClient = useQueryClient();
  return useMutation(({ postId, comment }) => createComment({ postId, comment }), {
    onSuccess: (response, { postId }) => {
      toastFlashMessage('Comment created', 'success');
      queryClient.invalidateQueries([streamCacheKeys.POSTS]);
      queryClient.invalidateQueries([streamCacheKeys.featuredPosts]);
      queryClient.invalidateQueries([streamCacheKeys.commentsOnAPost, postId]);
    },
  });
};

export const useCreatePost = () => {
  const queryClient = useQueryClient();
  return useMutation(
    ({ categoryId, post, additional_file }) => {
      return createPost({ categoryId, post, additional_file });
    },
    {
      onSuccess: () => {
        toastFlashMessage('Post created', 'success');
        queryClient.invalidateQueries([streamCacheKeys.POSTS]);
        queryClient.invalidateQueries([streamCacheKeys.homeDetails]);
      },
    }
  );
};

// Posts with pagination and filters
export const useGetPostsQuery = ({ user, category, paginationData, onSettled, time = 'all-time' }) => {  
  return useInfiniteQuery(
    [streamCacheKeys.POSTS, user, category, time],
    ({ pageParam = 1 }) => getPostsService({ user, category, page: pageParam, time }),
    {
      getNextPageParam: () => {
        const nextPage = paginationData?.current_page + 1;
        if (nextPage <= paginationData?.number_of_pages) return nextPage;
        return undefined;
      },
      onSettled,
    }
  );
};

export const useDeletePostMutation = () => {
  const queryClient = useQueryClient();
  return useMutation((postId) => deletePost(postId), {
    onSuccess: ({ message }) => {
      toastFlashMessage(message, 'success');
      queryClient.invalidateQueries([streamCacheKeys.POSTS]);
      queryClient.invalidateQueries([streamCacheKeys.homeDetails]);
    },
  });
};

export const useCreateReactionCheerComment = () => {
  const queryClient = useQueryClient();
  return useMutation((postId, commentId) => createReactionCheerComment(postId, commentId), {
    onSuccess: () => {
      queryClient.invalidateQueries([streamCacheKeys.commentsOnAPost]);
    },
  });
};

export const useCreateReactionLikeComment = () => {
  const queryClient = useQueryClient();
  return useMutation((postId, commentId) => createReactionLikeComment(postId, commentId), {
    onSuccess: () => {
      queryClient.invalidateQueries([streamCacheKeys.commentsOnAPost]);
    },
  });
};

export const useCreateReactionLoveComment = () => {
  const queryClient = useQueryClient();
  return useMutation((postId, commentId) => createReactionLoveComment(postId, commentId), {
    onSuccess: () => {
      queryClient.invalidateQueries([streamCacheKeys.commentsOnAPost]);
    },
  });
};

export const useCreateFeaturedPost = () => {
  const queryClient = useQueryClient();
  return useMutation((postId) => createFeaturedPost(postId), {
    onSuccess: () => {
      queryClient.invalidateQueries([streamCacheKeys.POSTS]);
      queryClient.invalidateQueries([streamCacheKeys.featuredPosts]);
      queryClient.invalidateQueries([streamCacheKeys.featuredPostsCount]);
    },
  });
};

export const useFeaturedPostsCountQuery = () =>
  useQuery([streamCacheKeys.featuredPostsCount], getFeaturedPostCount);

export const useGetURLMetadataServiceMutation = () => useMutation(getURLMetadataService);
