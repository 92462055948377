import React, { useState, useMemo } from 'react';
import { InputSelect, InputText, TextArea } from 'shared_ui_components';
import { useLearningPathsStore } from '../../LearningPaths.store';
import { useGetListUsersLearningPathQuery, useGetListUsersQuery } from '../../LearningPaths.queries';
import DatePicker from 'shared_ui_components/ScheduleTraining/Components/DatePicker';
import styles from './EditLearningPath.module.scss';
import DateTime from 'infrastructure/Datetime';
import User from 'infrastructure/User';
import Organization from 'infrastructure/Organization';
import { rolesLPLead, rolesLPAdmin } from 'constants';
import { FeatureFlag } from 'controllers/_exports';

const EditLearningPathContent = () => {
  const { currentLearningPath, setCurrentLearningPath } = useLearningPathsStore();
  const [valueList, setValueList] = useState(currentLearningPath?.users || []);
  const { data: usersList = [] } = useGetListUsersQuery();
  const { data: usersListLP = [] } = useGetListUsersLearningPathQuery();
  const [selectedRole, setSelectedRole] = useState(
    Organization.isAdmin() ? rolesLPAdmin()[0] : rolesLPLead()[0]
  );
  const options = useMemo(() => {
    if (FeatureFlag.enabled('LEARNING_PATH_NEW_LOGIC_ASSIGN')) {
      if (!usersListLP?.length > 0) return [];
      let filteredUsers = [];
      if (selectedRole.id !== -1) {
        filteredUsers = usersListLP.filter((user) => user.role_id === selectedRole.id);
      } else {
        filteredUsers = [...usersListLP];
      }
      return filteredUsers.map((user) => {
        return {
          label: User?.getUserName(user?.user),
          value: user.user.uuid,
          role: user.role,
          role_id: user.role_id
        };
      });
    } else {
      if (!usersList?.length > 0) return [];
      return usersList.map((user) => {
        return {
          label: User?.getUserName(user?.user),
          value: user.user.uuid,
          role: user.role,
          role_id: user.role_id
        };
      });
    }
  }, [usersListLP, selectedRole, usersList]);

  const handleChange = (key, value) => {
    setCurrentLearningPath({
      ...currentLearningPath,
      [key]: value,
    });
  };
  const handleChangeUsers = (values) => {
    delete currentLearningPath.users;
    let newValue = values;
    let a = [];
    if (values.some((item) => item.value === -1)) {
      if (!FeatureFlag.enabled('LEARNING_PATH_NEW_LOGIC_ASSIGN')) {
        newValue = usersList
        .map((user) => {
          return {
            label: User?.getUserName(user?.user),
            value: user.user.uuid,
            role: user.role,
            role_id: user.role_id
          };
        })
      } else {
        newValue = usersListLP
          .map((user) => {
            return {
              label: User?.getUserName(user?.user),
              value: user.user.uuid,
              role: user.role,
              role_id: user.role_id
            };
          })
      }
      if (selectedRole.id !== -1) {
        a = newValue.filter((user) => {
          return user.role === selectedRole.id;
        });
      } else {
        a = [...newValue]; 
      }
      setValueList(a);
      setCurrentLearningPath({ users: a, ...currentLearningPath });
      return;
    }
    setValueList(newValue);
    setCurrentLearningPath({ users: newValue, ...currentLearningPath });
  };

  const handleAcceptDate = (query = {}) => {
    setCurrentLearningPath({
      ...currentLearningPath,
      deadline: DateTime.formatDate(new Date(query)) + ' 00:00:00',
    });
  };

  const handleChangeRoles = (value) => {
    if (value !== null) {
      setSelectedRole(value);
    }
    setValueList([]);
  };
  return (
    <div style={{ display: 'flex', gap: '0.5rem', flexDirection: 'column', padding: '1rem' }}>
      <p className="heading5" style={{ marginBottom: '0.5rem' }}>
        Plan details
      </p>
      <InputText
        label={'Title'}
        placeholder={'New learning path'}
        onChange={(e) => handleChange('title', e.target.value)}
        value={currentLearningPath.title}
        onBlur={(e) => handleChange('title', e.target.value.trim())}
      />
      <TextArea
        label={'Description'}
        placeholder={'Write a detailed description to this learning path.'}
        onChange={(e) => handleChange('description', e.target.value)}
        value={currentLearningPath.description}
        onBlur={(e) => handleChange('description', e.target.value.trim())}
        rows={3}
      />
      <div className={styles.selectUsers}>
        {FeatureFlag.enabled('LEARNING_PATH_NEW_LOGIC_ASSIGN') && (
          <InputSelect
            className="reactSelect"
            closeMenuOnSelect={true}
            options={Organization.isLead() ? rolesLPLead() : rolesLPAdmin()}
            isSearchable={false}
            placeholder={'Select Role'}
            label="Select Role"
            onChange={(value) => handleChangeRoles(value)}
            noOptionsMessage={() => 'No matches found'}
          />
        )}
        <InputSelect
          isMulti
          closeMenuOnSelect={true}
          options={[{ label: 'All Users', value: -1 }, ...options]}
          label={`Select Users`}
          placeholder={`Select ${selectedRole.value !== -1 ? selectedRole.label : 'Users'}`}
          onChange={(values) => handleChangeUsers(values)}
          noOptionsMessage={() => 'No matches found'}
          isClearable
          value={valueList}
        />
      </div>
      <div>
        <p className={styles.deadlineTitle}>Deadline</p>
        <DatePicker
          InputLabel={'Deadline'}
          handleApply={(date) => handleAcceptDate(date)}
          defaultFilter={{
            date: currentLearningPath?.deadline
              ? DateTime.formatDate(new Date(currentLearningPath?.deadline))
              : DateTime.formatDate(new Date()),
          }}
        />
      </div>
    </div>
  );
};

export default EditLearningPathContent;
