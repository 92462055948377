import React from 'react';
import { Grid } from '@material-ui/core';
import { Link } from 'react-router-dom';
import styles from '../../../assets/styles/training.module.scss';
import { Skeleton } from '@material-ui/lab';
import { FeatureFlag } from 'controllers/_exports';

export default function Shimmer(props) {
  return (
    <>
      {props.type === 'SingleModuleCard' && (
        <>
          <section className={styles.setupModuleSection}>
            <div className={styles.setupModuleWrapper}>
              <Grid container direction="row" alignItems="center" spacing={2}>
                <Grid item lg={3} md={6} sm={12} xs={12}>
                  <Skeleton variant="text" width="30%" height={36} />
                </Grid>
                <Grid item lg={6} md={6} sm={12} xs={12}></Grid>
              </Grid>
              <Grid container direction="row" spacing={3} className={styles.contentRow}>
                {[0, 1, 2].map((item, key) => (
                  <Grid item xs={4} md={4} lg={4} key={key}>
                    <div key={key} className={styles.cardWrapper} style={{ marginBottom: 16 }}>
                      <div className={styles.topBlk}>
                        <div className={styles.content}>
                          <Skeleton
                            variant="circle"
                            width={34}
                            height={34}
                            style={{ background: 'rgba(0, 0, 0, 0.11)' }}
                          />
                          <Skeleton
                            variant="text"
                            width="30%"
                            height={30}
                            style={{ background: 'rgba(0, 0, 0, 0.11)', marginTop: 25 }}
                          />
                          <Skeleton
                            variant="text"
                            width="85%"
                            height={26}
                            style={{ background: 'rgba(0, 0, 0, 0.11)', marginTop: 15 }}
                          />
                        </div>
                      </div>
                      <div className={styles.bottomBlk}>
                        <Grid
                          container
                          direction="row"
                          alignItems="center"
                          spacing={2}
                          className={styles.moduleActionRow}
                        >
                          <Grid item lg={7} md={7} sm={12} xs={12}>
                            <p>
                              <Skeleton
                                variant="text"
                                width="50%"
                                height={20}
                                style={{ background: 'rgba(0, 0, 0, 0.11)' }}
                              />
                            </p>
                          </Grid>
                          <Grid item lg={5} md={5} sm={12} xs={12}>
                            <Skeleton
                              variant="text"
                              width="100%"
                              height={48}
                              style={{ background: 'rgba(0, 0, 0, 0.11)' }}
                            />
                          </Grid>
                        </Grid>
                      </div>
                    </div>
                  </Grid>
                ))}
              </Grid>
            </div>
          </section>
        </>
      )}
      {props.type == 'ModuleCard' && (
        <>
          <section className={styles.setupTrainingIntroSection} style={{ minHeight: '70px' }}>
            <Grid container direction="row" alignItems="center" spacing={2}>
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <div className={styles.titleWrapper}>
                  <div className={styles.imgBlk}>
                    <Skeleton
                      variant="circle"
                      width={48}
                      height={48}
                      style={{ background: 'rgba(0, 0, 0, 0.11)' }}
                    />
                  </div>
                  <div className={styles.contentBlk}>
                    <h1 className="heading4">
                      {' '}
                      <Skeleton variant="text" width="20%" style={{}} />
                    </h1>
                    <p className="supportText">
                      <Skeleton variant="text" width="60%" style={{}} />
                    </p>
                    <p className="supportText">
                      <Skeleton variant="text" width="60%" style={{}} />
                    </p>
                  </div>
                </div>
              </Grid>
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <div className={styles.stepsWrapper}>
                  <Grid container direction="row" alignItems="center" spacing={1}>
                    <Grid item lg={6} md={6} sm={12} xs={12}>
                      <div className={styles.progressBlk}>
                        <Skeleton variant="text" width="60%" style={{}} />
                      </div>
                    </Grid>
                    <Grid item lg={6} md={6} sm={12} xs={12}>
                      <div className={styles.stepsBlk}>
                        <h2 className="heading3">
                          <Skeleton variant="text" width="40%" style={{}} />
                        </h2>
                      </div>
                    </Grid>
                  </Grid>
                </div>
              </Grid>
            </Grid>
          </section>
          <section className={styles.setupModuleSection}>
            <div className={styles.setupModuleWrapper}>
              <Grid
                container
                direction="row"
                alignItems="center"
                spacing={2}
                className={styles.titleRow}
              >
                <Grid item lg={6} md={6} sm={12} xs={12}>
                  <Skeleton variant="text" width="30%" height={36} />
                </Grid>
                <Grid item lg={6} md={6} sm={12} xs={12} className="textRight">
                  <Skeleton variant="text" width="30%" height={36} style={{ float: 'right' }} />
                </Grid>
              </Grid>
              <Grid container direction="row" spacing={3} className={styles.contentRow}>
                {[0, 1, 2, 3, 4, 5].map((item, key) => (
                  <Grid item xs={4} md={4} lg={4} key={key}>
                    <Link
                      to="/"
                      key={key}
                      className={styles.cardWrapper}
                      style={{ marginBottom: 16 }}
                    >
                      <div className={styles.topBlk}>
                        <div className={styles.content}>
                          <Skeleton
                            variant="circle"
                            width={34}
                            height={34}
                            style={{ background: 'rgba(0, 0, 0, 0.11)' }}
                          />
                          <Skeleton
                            variant="text"
                            width="30%"
                            height={30}
                            style={{ background: 'rgba(0, 0, 0, 0.11)', marginTop: 25 }}
                          />
                          <Skeleton
                            variant="text"
                            width="85%"
                            height={26}
                            style={{ background: 'rgba(0, 0, 0, 0.11)', marginTop: 15 }}
                          />
                        </div>
                      </div>
                      <div className={styles.bottomBlk}>
                        <Grid
                          container
                          direction="row"
                          alignItems="center"
                          spacing={2}
                          className={styles.moduleActionRow}
                        >
                          <Grid item lg={7} md={7} sm={12} xs={12}>
                            <p>
                              <Skeleton
                                variant="text"
                                width="50%"
                                height={20}
                                style={{ background: 'rgba(0, 0, 0, 0.11)' }}
                              />
                            </p>
                          </Grid>
                          <Grid item lg={5} md={5} sm={12} xs={12}>
                            <Skeleton
                              variant="text"
                              width="100%"
                              height={48}
                              style={{ background: 'rgba(0, 0, 0, 0.11)' }}
                            />
                          </Grid>
                        </Grid>
                      </div>
                    </Link>
                  </Grid>
                ))}
              </Grid>
            </div>
          </section>
        </>
      )}
      {props.type == 'UserModuleCardTitle' && (
        <h2 className="heading4">
          {' '}
          <Skeleton
            variant="text"
            width="30%"
            height={40}
            style={{ background: 'rgba(0, 0, 0, 0.11)', marginTop: 0 }}
          />
        </h2>
      )}
      {props.type == 'UserModuleCard' && (
        <>
          {[0, 1, 2, 3, 4, 5, 6].map((item, key) => (
            <Grid item xs={4} md={4} lg={4} key={key}>
              <Link to="/" className={styles.cardWrapper} style={{ marginBottom: 16 }}>
                <div className={styles.topBlk}>
                  <div className={styles.content}>
                    <Skeleton
                      variant="circle"
                      width={34}
                      height={34}
                      style={{ background: 'rgba(0, 0, 0, 0.11)' }}
                    />
                    <Skeleton
                      variant="text"
                      width="30%"
                      height={30}
                      style={{ background: 'rgba(0, 0, 0, 0.11)', marginTop: 25 }}
                    />
                    <Skeleton
                      variant="text"
                      width="85%"
                      height={26}
                      style={{ background: 'rgba(0, 0, 0, 0.11)', marginTop: 15 }}
                    />
                  </div>
                </div>
                <div className={styles.bottomBlk}>
                  <Grid
                    container
                    direction="row"
                    alignItems="center"
                    spacing={2}
                    className={styles.moduleActionRow}
                  >
                    <Grid item lg={7} md={7} sm={12} xs={12}>
                      <p>
                        <Skeleton
                          variant="text"
                          width="50%"
                          height={20}
                          style={{ background: 'rgba(0, 0, 0, 0.11)' }}
                        />
                      </p>
                    </Grid>
                    <Grid item lg={5} md={5} sm={12} xs={12}>
                      <Skeleton
                        variant="text"
                        width="100%"
                        height={48}
                        style={{ background: 'rgba(0, 0, 0, 0.11)' }}
                      />
                    </Grid>
                  </Grid>
                </div>
              </Link>
            </Grid>
          ))}
        </>
      )}
      {props.type == 'overView' && (
        <>
          {[0, 1, 2, 3, 4, 5].map((item, key) => (
            <Link to="/" className={styles.cardWrapper} style={{ marginBottom: 16 }} key={key}>
              <div className={styles.topBlk}>
                <div className={styles.content}>
                  <Skeleton
                    variant="circle"
                    width={34}
                    height={34}
                    style={{ background: 'rgba(0, 0, 0, 0.11)' }}
                  />
                  <Skeleton
                    variant="text"
                    width="30%"
                    height={30}
                    style={{ background: 'rgba(0, 0, 0, 0.11)', marginTop: 25 }}
                  />
                  <Skeleton
                    variant="text"
                    width="85%"
                    height={26}
                    style={{ background: 'rgba(0, 0, 0, 0.11)', marginTop: 15 }}
                  />
                </div>
              </div>
              <div className={styles.bottomBlk}>
                <Grid
                  container
                  direction="row"
                  alignItems="center"
                  spacing={2}
                  className={styles.moduleActionRow}
                >
                  <Grid item lg={7} md={7} sm={12} xs={12}>
                    <p>
                      <Skeleton
                        variant="text"
                        width="50%"
                        height={20}
                        style={{ background: 'rgba(0, 0, 0, 0.11)' }}
                      />
                    </p>
                  </Grid>
                  <Grid item lg={5} md={5} sm={12} xs={12}>
                    <Skeleton
                      variant="text"
                      width="100%"
                      height={48}
                      style={{ background: 'rgba(0, 0, 0, 0.11)' }}
                    />
                  </Grid>
                </Grid>
              </div>
            </Link>
          ))}
        </>
      )}
      {props.type == 'TrainingOverview' && (
        <>
          <section className="overviewProgressSection">
            <Grid container direction="row" spacing={3}>
              <Grid item xs={12} md={6} className="flexNotCentered">
                <div className="progressChart" style={{ position: 'relative' }}>
                  <h3 className="heading5">
                    <Skeleton variant="text" width="25%" height={34} style={{}} />
                  </h3>
                  <Skeleton
                    variant="circle"
                    width={150}
                    height={150}
                    style={{ marginLeft: 100, marginTop: 40 }}
                    className={styles.overviewCircle}
                  />
                  <div
                    style={{
                      position: 'absolute',
                      top: 83,
                      left: '56%',
                      width: '100%',
                    }}
                  >
                    <Skeleton variant="text" width="15%" height={34} style={{ marginBottom: 10 }} />
                    <Skeleton variant="text" width="35%" height={24} style={{ marginBottom: 25 }} />
                    <Skeleton variant="text" width="25%" height={24} style={{ marginBottom: 20 }} />
                    <Skeleton variant="text" width="25%" height={24} style={{ marginBottom: 20 }} />
                    <Skeleton variant="text" width="25%" height={24} style={{}} />
                  </div>
                </div>
              </Grid>
              <Grid item xs={12} md={6}>
                <div className="completionTime" style={{ position: 'relative' }}>
                  <Skeleton
                    variant="text"
                    width={60}
                    height={90}
                    style={{ position: 'absolute', left: 20, top: 8 }}
                  />
                  <h3 className="heading3">
                    <Skeleton variant="text" width="40%" height={34} style={{ marginLeft: 16 }} />
                  </h3>
                  <p className="paragraph">
                    <Skeleton variant="text" width="60%" height={24} style={{ marginLeft: 16 }} />
                  </p>
                </div>

                <div className="overallRatings">
                  <h3>
                    <Skeleton variant="text" width="20%" height={24} style={{}} />
                  </h3>
                  <Grid container direction="row" spacing={2}>
                    <Grid item xs={12} md={6}>
                      <h6 className="heading1">
                        <Skeleton
                          variant="text"
                          width="20%"
                          height={45}
                          style={{ marginBottom: 15 }}
                        />
                      </h6>

                      <p className="paragraph">
                        <Skeleton
                          variant="text"
                          width="40%"
                          height={24}
                          style={{ marginBottom: 10 }}
                        />
                        <Skeleton
                          variant="text"
                          width="40%"
                          height={24}
                          style={{ marginBottom: 10 }}
                        />
                      </p>
                    </Grid>
                    <Grid item xs={12} md={6} className="ratingList">
                      <ul className="listUnstyled">
                        <Skeleton variant="text" width="50%" height={24} style={{}} />
                        <Skeleton variant="text" width="50%" height={24} style={{}} />
                        <Skeleton variant="text" width="50%" height={24} style={{}} />
                        <Skeleton variant="text" width="50%" height={24} style={{}} />
                        <Skeleton variant="text" width="50%" height={24} style={{}} />
                      </ul>
                    </Grid>
                  </Grid>
                </div>
              </Grid>
            </Grid>
          </section>
        </>
      )}
      {props.type == 'TrainingModuleDetails' &&
        [0, 1, 2, 3, 4, 5, 6, 7, 8].map((item, key) => (
          <section className={' dashboardListing'} key={key}>
            <Grid container direction="row">
              <Grid item md={12}>
                <div className="contentBlock" style={{ position: 'relative' }}>
                  <Grid container direction="row" spacing={4} className="titleRow">
                    <Grid item md={4}>
                      <Skeleton variant="text" width="55%" height={24} style={{ marginTop: 10 }} />
                    </Grid>
                    <Grid item md={5}>
                      <Skeleton variant="text" width="55%" height={24} style={{ marginTop: 10 }} />
                    </Grid>
                    <Grid item md={3}>
                      <Skeleton variant="text" width="40%" height={24} style={{ marginTop: 10 }} />
                    </Grid>
                  </Grid>
                </div>
              </Grid>
            </Grid>
          </section>
        ))}
      {props.type == 'TrainingTeamProgress' &&
        [0, 1, 2, 3, 4, 5, 6, 7, 8].map((item, key) => (
          <section className={' dashboardListing'} key={key}>
            <Grid container direction="row">
              <Grid item md={12}>
                <div className="contentBlock" style={{ position: 'relative' }}>
                  <Grid container direction="row" spacing={4} className="titleRow">
                    <Grid item md={3}>
                      <Skeleton
                        variant="circle"
                        width={40}
                        height={40}
                        style={{ position: 'absolute', top: 15, left: 0 }}
                      />
                      <Skeleton
                        variant="text"
                        width="45%"
                        height={24}
                        style={{ marginLeft: 50, marginTop: 8 }}
                      />
                      <Skeleton
                        variant="text"
                        width="65%"
                        height={18}
                        style={{ marginLeft: 50, marginBottom: 10 }}
                      />
                    </Grid>
                    <Grid item md={FeatureFlag.enabled('TRACK_TIME_IMPROVEMENTS') ? 3 : 4}>
                      <Skeleton variant="text" width="90%" height={42} style={{ marginTop: 10 }} />
                    </Grid>
                    <Grid item md={FeatureFlag.enabled('TRACK_TIME_IMPROVEMENTS') ? 6 : 5}>
                      <Grid container direction="row" spacing={4} className="titleRow">
                        <Grid
                          item
                          md={FeatureFlag.enabled('TRACK_TIME_IMPROVEMENTS') ? 3 : 4}
                          style={{ position: 'relative' }}
                        >
                          <Skeleton
                            variant="text"
                            width="15%"
                            height={24}
                            style={{ marginTop: 15 }}
                          />
                          <Skeleton
                            variant="circle"
                            width={30}
                            height={30}
                            style={{ position: 'absolute', top: 28, left: 55 }}
                          />
                        </Grid>
                        <Grid item md={FeatureFlag.enabled('TRACK_TIME_IMPROVEMENTS') ? 3 : 4}>
                          <Skeleton
                            variant="text"
                            width="52%"
                            height={24}
                            style={{ marginTop: 10 }}
                          />
                        </Grid>
                        {FeatureFlag.enabled('TRACK_TIME_IMPROVEMENTS') && (
                          <Grid item md={FeatureFlag.enabled('TRACK_TIME_IMPROVEMENTS') ? 3 : 4}>
                            <Skeleton
                              variant="text"
                              width="52%"
                              height={24}
                              style={{ marginTop: 10 }}
                            />
                          </Grid>
                        )}
                        <Grid item md={FeatureFlag.enabled('TRACK_TIME_IMPROVEMENTS') ? 3 : 4}>
                          <Skeleton
                            variant="text"
                            width="52%"
                            height={24}
                            style={{ marginTop: 10 }}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </div>
              </Grid>
            </Grid>
          </section>
        ))}
      {props.type == 'TrainingAssessment' &&
        [0, 1, 2, 3, 4].map((item, key) => (
          <div
            className={styles.cardItem + ' ' + styles.trainingAssessmentShimmer}
            style={{ borderBottom: '1px solid #dbeaf4', paddingTop: 32, paddingBottom: 48 }}
            key={key}
          >
            <Grid
              container
              direction="row"
              alignItems="center"
              spacing={2}
              className={styles.titleRow}
            >
              <Grid item lg={9} md={9} sm={12} xs={12}>
                <h3 className="heading5">
                  {' '}
                  <Skeleton variant="text" width="25%" height={36} style={{ marginTop: 0 }} />
                </h3>
                <p className="supportText">
                  {' '}
                  <Skeleton variant="text" width="15%" height={24} style={{ marginTop: 0 }} />
                </p>
                <Skeleton
                  variant="text"
                  width="9%"
                  height={24}
                  style={{ marginTop: 20, display: 'inline-block' }}
                />
                <Skeleton
                  variant="text"
                  width="6%"
                  height={24}
                  style={{ marginTop: 20, display: 'inline-block', marginLeft: 15 }}
                />
                <Skeleton
                  variant="text"
                  width="6%"
                  height={24}
                  style={{ marginTop: 20, display: 'inline-block', marginLeft: 8 }}
                />
              </Grid>
            </Grid>
          </div>
        ))}
      {props.type == 'FeedbackAssessmentDialog' && (
        <div className={styles.formItem}>
          <Grid
            container
            direction="row"
            alignItems="center"
            spacing={4}
            className={styles.contentRow}
          >
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <div className={styles.assesmentContent}>
                <h3 className="heading5">
                  <Skeleton variant="text" width="20%" height={24} style={{}} />
                </h3>
                <p className="supportText">
                  <Skeleton variant="text" width="40%" height={18} style={{ marginTop: 0 }} />
                </p>

                <div className={styles.viewBlk} style={{ position: 'relative' }}>
                  <Skeleton
                    variant="text"
                    width="25%"
                    height={85}
                    style={{ marginTop: 5, transform: 'none', display: 'inline-block' }}
                  />
                  <Skeleton
                    variant="text"
                    width="13%"
                    height={36}
                    style={{
                      transform: 'none',
                      display: 'inline-block',
                      marginLeft: 20,
                      position: 'absolute',
                      top: 35,
                    }}
                  />
                </div>
                <Grid
                  container
                  direction="row"
                  alignItems="center"
                  spacing={4}
                  className={styles.titleRow}
                >
                  {[0, 1].map((item, key) => (
                    <Grid item lg={6} md={6} sm={12} xs={12} key={key}>
                      <h4 className="heading5">
                        <Skeleton variant="text" width="40%" height={24} style={{ marginTop: 8 }} />
                      </h4>
                      <div className={styles.customRating}>
                        <Skeleton variant="text" width="80%" height={30} style={{ marginTop: 8 }} />
                      </div>
                      <Skeleton variant="text" width="100%" height={100} style={{}} />
                    </Grid>
                  ))}
                </Grid>
              </div>
            </Grid>
          </Grid>
        </div>
      )}
      {props.type == 'UserAssessmentDialog' &&
        [0, 1, 2, 3, 4].map((item, key) => (
          <Grid
            container
            direction="row"
            alignItems="center"
            spacing={4}
            className={styles.contentRow}
            key={key}
          >
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <div className={styles.assesmentContent}>
                <h3 className="heading5">
                  <Skeleton variant="text" width="20%" height={24} style={{}} />
                </h3>
                <p className="supportText">
                  <Skeleton variant="text" width="40%" height={18} style={{ marginTop: 0 }} />
                </p>
                <div className={styles.uploadVedioBlk}>
                  <Skeleton
                    variant="text"
                    width="24%"
                    height={34}
                    style={{ marginTop: 2, transform: 'none' }}
                  />
                </div>
              </div>
            </Grid>
          </Grid>
        ))}
    </>
  );
}
