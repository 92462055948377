import {
  globalGetService,
  globalPostService,
  globalDeleteService,
  globalExportService,
  globalPutService,
} from '../../utils/globalApiServices';
import { getStore } from '../../store/GlobalStore';
import { lateCheckin } from '../tracking/apiServices';
import { FeatureFlag } from 'controllers/_exports';

export function getRecruitmentOverviewApi(query = {}) {
  return new Promise((resolve, reject) => {
    globalGetService(`/home/recruitment/`, query)
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });
}
export function getOnboardingOverviewApi({ query = {}, time = 'all-time' }) {
  return new Promise((resolve, reject) => {
    let url = `/home/onboarding/?time_lapse=${time}`;
    globalGetService(url, query)
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });
}
export function getTrainingOverviewApi({ query = {}, time = 'all-time' }) {
  return new Promise((resolve, reject) => {
    let url = `/home/training/?time_lapse=${time}`;
    globalGetService(url, query)
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });
}
export function getTrackingOverviewApi(query = {}) {
  return new Promise((resolve, reject) => {
    globalGetService(`/home/tracking/`, query)
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });
}
export function getUserPreferencesApi(query = {}) {
  return new Promise((resolve, reject) => {
    globalGetService(`/category/details/`, query)
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });
}
export function getOverallDetailsApi({ query = {}, time = 'all-time' }) {
  return new Promise((resolve, reject) => {
    let url = `/overall/details/?time_lapse=${time}`;
    globalGetService(url, query)
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export function checkinUpdateApi(data, start_date) {
  if (!!start_date) return lateCheckin({ data, start_date });
  return new Promise((resolve, reject) => {
    globalPostService(`/tracking/goals/checkin/`, data)
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export function timezoneUpdateApi(query = {}) {
  const postData = new FormData();
  postData.append('timezone', query.timezone);
  globalPostService(`/profile-update/`, postData).then((response) => {
    if (response.statusCode === 200) {
      const userInfo = JSON.parse(localStorage.getItem('userInfo'));
      localStorage.setItem('userInfo', JSON.stringify({ ...userInfo, user: response.data }));
      const { dispatch } = getStore();
      dispatch({
        type: 'SET_USER',
        payload: response.data,
      });
    }
  });
}

export function getNotificationPreferences() {
  return new Promise((resolve, reject) => {
    globalGetService(`/notifications/preferences/`)
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export function updateNotificationPreferences(to_update) {
  return new Promise((resolve, reject) => {
    globalPutService(`/notifications/update-preferences/`, { to_update })
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });
}
