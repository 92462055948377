import React from 'react';
import { useHistory } from 'react-router-dom';
import { imageBasePath } from 'constants';
import { useSalesRepView } from 'features/salesRepView/SalesRepView.hook';
import classes from './RepPovBanner.module.scss';
import { ConfirmationDialog } from 'shared_elements';
import { useSalesRepViewStore } from 'features/salesRepView/SalesRepView.store';

const RepPovBanner = ({ aux = false }) => {
  const history = useHistory();
  const { showBanner, onCloseBanner, switchOff, switchOn, isActive, isPathSupported } =
    useSalesRepView();
  const { isConfirmationOpen, closeConfirmation, openConfirmation } = useSalesRepViewStore();

  const handleSubmit = () => {
    closeConfirmation();
    if (!isPathSupported) history.push('/onboarding');
    isActive ? switchOff() : switchOn();
  };

  if (aux) return showBanner ? <div className={classes.aux} /> : null;
  return (
    <>
      {showBanner && (
        <div className={classes.banner}>
          <p>Currently in Learner Mode. Click 'Switch back' to return to your original role </p>
          <button onClick={openConfirmation}>Switch Back</button>
          <img alt="close" src={imageBasePath + 'close.svg'} onClick={onCloseBanner} />
        </div>
      )}
      <ConfirmationDialog
        open={isConfirmationOpen}
        title={
          <span>
            Are you sure you want to switch to{' '}
            <b>{isActive ? 'your original role' : 'Learner Mode'}</b>?
          </span>
        }
        handleClose={closeConfirmation}
        rejectText="No, cancel"
        acceptText="Yes, change view"
        image={imageBasePath + 'admin_view_switch.svg'}
        handleSubmit={handleSubmit}
        description={isActive ? '' : 'Please note that some features may be limited in this view.'}
      />
    </>
  );
};

export default RepPovBanner;
