import { useEffect, useMemo } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { useChangeSalesRepViewMutation, useGetSalesRepViewQuery } from './SalesRepView.queries';
import Organization from 'infrastructure/Organization';
import { FeatureFlag } from 'controllers/_exports';
import { useLearningPathsStore } from 'features/training/components/LearningPaths/LearningPaths.store';
import { useSalesRepViewStore } from './SalesRepView.store';

const SUPPORTED_PATHS = ['/training', '/learning-path', '/onboarding'];

export const useSalesRepView = ({ requestValue = false } = {}) => {
  const location = useLocation();

  const isPathSupported = useMemo(() => {
    try {
      return SUPPORTED_PATHS.includes(location?.pathname?.match(/^\/[^/]+/)[0]);
    } catch (error) {
      return false;
    }
  }, [location]);

  const enabled =
    FeatureFlag.enabled('SALES_REP_POV') && (Organization.isAdmin() || Organization?.isLead());

  const { data: isActive = false, isLoading } = useGetSalesRepViewQuery({
    requestValue,
    enabled,
  });
  const loading = useMemo(() => {
    if (enabled) return isLoading;
    return false;
  }, [isLoading, enabled]);
  const { mutate, isLoading: mutationLoading } = useChangeSalesRepViewMutation();

  const switchOff = () => {
    if (mutationLoading) return;
    mutate({ status: false });
  };
  const switchOn = () => {
    if (mutationLoading) return;
    mutate({ status: true });
  };

  // Learning path handlers
  const history = useHistory();
  const { setSelectedRepLearningPathID, setActiveTabRep } = useLearningPathsStore();

  useEffect(() => {
    if (mutationLoading) return;
    if (!location?.pathname.includes('/learning-path/')) return;
    if (!(Organization.isAdmin() || Organization.isLead())) return;

    const params = new URLSearchParams(location.search);
    const hasQuery = params.has('sales_rep_view') && params.get('sales_rep_view') === 'true';
    if (!hasQuery) return;

    const id = location?.pathname?.replace('/learning-path/', '');

    history.push('/training');
    setActiveTabRep('learning');
    setSelectedRepLearningPathID(id);
    setTimeout(() => mutate({ status: true }), 200);
  }, [
    mutate,
    history,
    location?.pathname,
    location.search,
    mutationLoading,
    setSelectedRepLearningPathID,
    setActiveTabRep,
  ]);

  // Banner
  const { closeBanner, isBannerClosed } = useSalesRepViewStore();
  const onCloseBanner = () => {
    localStorage.setItem('closedRepViewBanner', true);
    closeBanner();
  };
  const showBanner = useMemo(() => {
    const closedForSession = Boolean(localStorage.getItem('closedRepViewBanner')) || false;
    return !closedForSession && !isBannerClosed && isActive;
  }, [isBannerClosed, isActive]);

  return {
    isPathSupported,
    isActive,
    isLoading: loading,
    showBanner,
    onCloseBanner,
    switchOff,
    switchOn,
    enabled,
  };
};
