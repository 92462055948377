import React from 'react';
import classes from './RepPovSwitch.module.css';
import { MenuItem, Switch, withStyles } from '@material-ui/core';
import classNames from 'classnames';
import { useSalesRepView } from 'features/salesRepView/SalesRepView.hook';
import { useSalesRepViewStore } from 'features/salesRepView/SalesRepView.store';

const RepPovSwitch = () => {
  const { isActive, enabled } = useSalesRepView();
  const { openConfirmation } = useSalesRepViewStore();

  if (!enabled) return null;
  return (
    <div className={classes.item}>
      <MenuItem onClick={openConfirmation} className={classNames(['supportText', 'menuItem'])}>
        <span>Learner mode</span>
        <GreenSwitch checked={isActive} />
      </MenuItem>
    </div>
  );
};

const GreenSwitch = withStyles({
  root: {
    cursor: 'pointer',
    width: 28,
    height: 16,
    padding: 0,
    overflow: 'visible',
  },
  switchBase: {
    padding: 2,
    color: '#FFF',
    '&$checked': {
      transform: 'translateX(12px)',
      color: '#FFF',
      '& + $track': {
        opacity: 1,
        backgroundColor: '#2DC38D',
        borderColor: '#2DC38D',
      },
    },
  },
  thumb: {
    width: 12,
    height: 12,
    boxShadow: 'none',
  },
  track: {
    border: `1px solid #BFBFBF`,
    borderRadius: 16 / 2,
    opacity: 1,
    backgroundColor: '#87A0B4',
  },
  checked: {},
})(Switch);

export default RepPovSwitch;
