import React, { useState, useEffect, useContext } from 'react';
import {
  Grid,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  Menu,
  MenuItem,
  List,
  ListItem,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import {
  CustomButton,
  InputText,
  CustomTooltipWhite,
  CustomCheckbox,
  CustomLink,
} from '../shared_ui_components';
import { imageBasePath, roles, regExpression } from '../constants';
import { toastFlashMessage } from '../utils';
import { inviteUsers } from '../features/users/apiServices';
import { fieldValidation } from '../utils/formValidation';
import { GlobalContext } from '../context/GlobalContext';
import SuccessDialog from './SuccessDialog';
import { FeatureFlag, Subscription } from 'controllers/_exports';
import { updateLocalSubscription } from 'features/payment/Payment.utils';
import SilentMode from 'features/users/components/Settings/SilentMode';

const newUser = {
  email: '',
  role: '',
  is_onboarding: true,
  is_training: true,
  error: '',
  errorCode: {
    email: {
      0: '',
      1: 'Please enter email address',
      4: 'Invalid email address',
      5: 'You are Unable to invite user(s)',
      6: 'You are Unable to invite user(s)',
    },
    emailObj: {
      requiredFlag: true,
      regexPattern: regExpression.email,
    },
    role: {
      0: '',
      1: 'Please select the correct role',
    },
    roleObj: {
      requiredFlag: true,
    },
  },
};

export default function InviteUserDialog(props) {
  const { open, email } = props;
  const [loader, setLoader] = useState(false);
  const [formData, setFormData] = useState([newUser]);
  const { state, dispatch } = useContext(GlobalContext);
  const [availableRoles, availableRolesSet] = useState([]);
  const [openSuccess, setOpenSuccess] = useState(false);
  const [invitedUsers, setInvitedUsers] = useState([]);

  useEffect(() => {
    setFormData([{ ...newUser, email: props.email ? props.email : '' }]);
    if (props.open) {
      if (state.role && state.role.id === 4) {
      } else if (state.role && state.role.id === 3) {
        availableRolesSet(roles().filter((role) => ![1, 2, 3].includes(role.id)));
        setFormData([{ ...newUser, role: 4 }]);
      } else if (state.role && state.role.id === 2) {
        availableRolesSet(roles().filter((role) => ![1].includes(role.id)));
      } else {
        availableRolesSet(roles().filter((role) => ![1].includes(role.id)));
      }
    } else {
      availableRolesSet([]);
    }
  }, [props.open]);

  const handleChange = (key, value, index) => {
    if (formData[index]) {
      let updatedUser = formData[index];
      updatedUser = {
        ...updatedUser,
        [key]: value,
        error: {
          ...updatedUser['error'],
          [key]: '',
        },
      };
      let updatedFormData = formData.map((item, i) => (i === index ? updatedUser : item));
      setFormData(updatedFormData);
    }
  };

  const handleDeleteUser = (index) => {
    if (formData[index]) {
      let updatedFormData = formData.filter((item, i) => i != index);
      setFormData(updatedFormData);
    }
  };

  const handleClose = () => {
    props.handleClose();
  };

  const handleAddNewUser = () => {
    if (
      !Subscription.allow({
        key: Subscription.keys.users,
        data: { extraUsers: formData.length },
      })
    )
      return;

    if (state.role && state.role.id === 3) {
      setFormData([...formData, { ...newUser, role: 4 }]);
    } else {
      setFormData([...formData, newUser]);
    }
  };

  const handleSubmit = (e) => {
    let emailArray = [];
    e.preventDefault();
    e.stopPropagation();
    setLoader(true);
    let usersList = formData;
    usersList.forEach((user, index) => {
      const { errorCode } = user;
      let validateNewInput = {
        email:
          errorCode['email'][
            fieldValidation({
              ...errorCode['emailObj'],
              fieldval: user.email,
            })
          ],
        role: errorCode['role'][
          fieldValidation({
            ...errorCode['roleObj'],
            fieldval: user.role?.toString(),
          })
        ],
      };

      if (state.user.email === user.email) {
        validateNewInput = {
          ...validateNewInput,
          email: errorCode['email'][5],
        };
      }

      if (emailArray.includes(user.email)) {
        validateNewInput = {
          ...validateNewInput,
          email: errorCode['email'][6],
        };
      } else {
        emailArray.push(user.email);
      }

      usersList = usersList.map((usr, key) =>
        key === index ? { ...usr, error: validateNewInput } : usr
      );
    });
    setFormData(usersList);
    let isInvlid = true;
    usersList.forEach((user) => {
      if (
        Object.values(user.error).every((k) => {
          return k == '';
        })
      ) {
      } else {
        isInvlid = false;
        setLoader(false);
        return;
      }
    });

    if (isInvlid) {
      usersList = usersList.map((user) => {
        return user;
      });
      inviteUsers({ users_list: usersList })
        .then((response) => {
          setLoader(false);
          if (response.statusCode == 200) {
            setInvitedUsers(usersList);
            handleClose();
            // toastFlashMessage(response.message, 'success');
            setOpenSuccess(true);
            dispatch({
              type: `UPDATE_USERS_LISTING`,
              payload: true,
            });
            // Update the number of active users
            updateLocalSubscription(response?.data?.subscription);
          } else {
            if (response.data && response.data?.error_list && response.data?.error_list.length) {
              // let errorMsg = ''
              // response.data?.error_list.forEach((error, index) => {
              //   errorMsg += `${error.email} - ${error.message}${index == response.data?.error_list.length - 1 ? '' : ',<br/> '}`
              // })
              // toastFlashMessage(errorMsg, 'error');
              let updatedFormData = formData;
              response.data?.error_list.forEach((error, index) => {
                let user = formData.find((user) => user.email == error.email);
                user['error'] = {
                  ...user['error'],
                  email: error.message,
                };
                updatedFormData = updatedFormData.map((item) =>
                  item.email == user.email ? user : item
                );
              });
              setFormData(updatedFormData);
              // toastFlashMessage(response.message, 'error');
            } else {
              toastFlashMessage(response.message, 'error');
            }
          }
        })
        .catch((err) => {
          setLoader(false);
          toastFlashMessage(err?.message || 'Error while inviting user', 'error');
        });
    }
  };
  const isDisabled = () => {
    return formData.some(
      (user) => user.error && Object.keys(user.error).some((k) => user.error[k] != '')
    );
  };
  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="scroll-dialog-title"
        className={' dsp-dialogs inviteUserDialog'}
        maxWidth="md"
      >
        <DialogTitle className="dialogTitle" id="scroll-dialog-title">
          <span className="heading2 title">Invite users</span>
          <span className="heading5 description">
            Invite your company members and define <br /> their roles
          </span>
        </DialogTitle>
        <img className="featuredImg" src={imageBasePath + 'invite_user_img.png'} alt="image" />
        <CloseIcon className="dialogClose" onClick={props.handleClose} />
        <DialogContent className="dialogContent">
          <div className="dialogContentWrapper">
            <div className="emailWrapper">
              {formData.map((item, index) => (
                <InviteUserItem
                  totalUsers={formData.length}
                  handleDeleteUser={handleDeleteUser}
                  user={item}
                  key={index}
                  index={index}
                  handleAddNewUser={handleAddNewUser}
                  handleChange={handleChange}
                  availableRoles={availableRoles}
                  role={state?.role?.id}
                />
              ))}
            </div>
          </div>
        </DialogContent>
        <DialogActions className="dialogActions textRight">
          <CustomButton
            children={loader ? 'Inviting...' : 'Invite'}
            className={`primaryBtn ${loader || isDisabled() ? 'disabledBtn' : ''}`}
            onClick={(e) => {
              setLoader(true);
              handleSubmit(e);
            }}
          />
        </DialogActions>
      </Dialog>
      <SuccessDialog
        open={openSuccess}
        handleClose={() => setOpenSuccess(false)}
        image={`resend_invitation_img.svg`}
        title={`Invitation sent successfully`}
        description={
          invitedUsers.length == 1
            ? `You have successfully invited ${invitedUsers[0].email}`
            : `You have successfully invited ${invitedUsers.length} users`
        }
        handleSubmit={() => setOpenSuccess(false)}
        acceptText={`OK`}
      />
    </div>
  );
}

function InviteUserItem(props) {
  const {
    index,
    loader,
    handleAddNewUser,
    handleChange,
    handleDeleteUser,
    user,
    totalUsers,
    availableRoles,
    role,
  } = props;
  const [anchorEl, setAnchorEl] = useState(null);
  const handleClick = (event, key) => {
    setAnchorEl(event.currentTarget);
  };
  const verifyOrgPreference = (role) => {
    const selectedOrg = JSON.parse(localStorage.getItem('userInfo'))?.selectedOrg;
    if (role == 3 && selectedOrg?.sales_lead_require_onboarding) {
      return true;
    } else if (role == 4 && selectedOrg?.sales_rep_require_onboard_training) {
      return true;
    } else {
      if (user.is_onboarding) {
        handleChange('is_onboarding', false, index);
      }
      if (user.is_training) {
        handleChange('is_training', false, index);
      }
      return false;
    }
  };
  const handleSilentMode = (value) => {
    handleChange('silent_mode', value, index);
  };
  return (
    <div className="listItem">
      <Grid
        container
        justify="flex-end"
        direction="row"
        alignItems="center"
        spacing={0}
        className="emailRow"
      >
        <Grid item xs={8} className="leftBlk">
          <InputText
            id="email"
            placeholder="Enter Email ID"
            value={user.email}
            onChange={(e) => handleChange('email', e.target.value, index)}
            onBlur={(e) => {
              if (user.email != user.email.trim()) {
                handleChange('email', e.target.value.trim(), index);
              }
            }}
          />
        </Grid>
        <Grid item xs={4} className={`rightBlk ${role === 3 ? 'disabled' : ''}`}>
          <p className={`selectRole `}>
            <a
              aria-controls={'simple-menu-' + index}
              className={'more'}
              aria-haspopup="true"
              onClick={(e) => {
                handleClick(e);
              }}
            >
              <span className={`paragraph ${user.role ? 'active' : ''}`}>
                {user.role
                  ? availableRoles.find((role) => role.id === user.role).label
                  : `User role`}
              </span>
              <img
                className="more"
                src={imageBasePath + 'down_arrow_gray.svg'}
                alt="More"
                style={{ verticalAlign: 'middle' }}
              />
            </a>
          </p>
          <Menu
            id={'simple-menu-' + index}
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl) ? true : false}
            onClose={(e) => {
              setAnchorEl(null);
              e.stopPropagation();
            }}
            className="moreMenu"
          >
            {availableRoles.map((item) => (
              <MenuItem
                onClick={(e) => {
                  handleChange('role', item.id, index);
                  setAnchorEl(null);
                }}
                key={item.id}
              >
                <span className="supportText">{item.label}</span>
              </MenuItem>
            ))}
          </Menu>
        </Grid>
        {totalUsers == 1 ? null : (
          <a className="deleteInvite">
            <img
              src={imageBasePath + 'delete_red.svg'}
              alt="Delete"
              onClick={() => handleDeleteUser(index)}
            />
          </a>
        )}
      </Grid>
      <Grid
        container
        justify="flex-end"
        direction="row"
        alignItems="center"
        spacing={0}
        className="infoRow"
      >
        <Grid item xs={8} className="leftBlk">
          {user.role && [3, 4].includes(user.role) && verifyOrgPreference(user.role) ? (
            <List className="listInline">
              <ListItem className="listInlineItem">
                <span className="supportText">Required step(s)</span>
                {user.role === 4 ? (
                  <CustomTooltipWhite
                    tooltipIcon="info_icon.svg"
                    title="These modules are mandated for sales rep in order to do their first check-in"
                  />
                ) : (
                  <CustomTooltipWhite
                    tooltipIcon="info_icon.svg"
                    title={`This module is mandated to sales ${FeatureFlag.enabled("RENAME_SALES_LEAD") ? 'management role' : 'lead'} in order to manage training and track modules`}
                  />
                )}
              </ListItem>
              <ListItem className="listInlineItem">
                <CustomCheckbox
                  label="Onboarding"
                  value={user.is_onboarding ? true : false}
                  onChange={(val) => handleChange('is_onboarding', !user.is_onboarding, index)}
                />
              </ListItem>
              <>
                {user.role === 4 ? (
                  <ListItem className="listInlineItem">
                    <CustomCheckbox
                      label="Training"
                      value={user.is_training ? true : false}
                      onChange={(val) => handleChange('is_training', !user.is_training, index)}
                    />
                  </ListItem>
                ) : null}
              </>
            </List>
          ) : null}
        </Grid>
        <Grid item xs={4} className="rightBlk">
          {totalUsers === index + 1 && Subscription.showInviteButton(totalUsers) && (
            <CustomLink
              linkimg="add.svg"
              linktext={loader ? 'Adding...' : 'Add More'}
              onClick={handleAddNewUser}
              className={`linkBtn ${loader ? 'disabledSubmit' : ''}`}
            />
          ) }
        </Grid>
      </Grid>

      {user?.role === 4 && (
        <SilentMode settings={user} variant="checkbox" onChange={handleSilentMode} />
      )}

      {user?.error?.email ? <h6 className="errorMsg">{user?.error?.email}</h6> : null}
      {user?.error?.role && !user?.error?.email ? (
        <h6 className="errorMsg">{user?.error?.role}</h6>
      ) : null}
    </div>
  );
}
