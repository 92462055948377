import React, { useMemo } from 'react';
import styles from './ReviewRoleplay.module.scss';
import { useLocation, useHistory } from 'react-router-dom';
import { imageBasePath } from 'constants';
import { useRoleplayStore } from 'features/roleplay/Roleplay.store';
import RoleplayLabel from '../Common/RoleplayLabel';
import Video from '../Common/Video';
import DropdownListScenarios from './DropdownListScenarios';

const ReviewRoleplayContent = () => {
  const roleplayView = useRoleplayStore((state) => state?.roleplayView);
  const reviewScenarioSelected = useRoleplayStore((state) => state?.reviewScenarioSelected);
  const setReviewScenarioSelected = useRoleplayStore((state) => state?.setReviewScenarioSelected);
  const setResponseRetake = useRoleplayStore((state) => state?.setResponseRetake);
  const secondAttempt = useRoleplayStore((state) => state?.secondAttempt);
  const showSecondAttempt = useRoleplayStore((state) => state?.showSecondAttempt);
  const hideSecondAttempt = useRoleplayStore((state) => state?.hideSecondAttempt);
  let location = useLocation();
  let history = useHistory();
  const handleBack = () => {
    history.push({
      pathname: `/roleplay`,
      state: {
        prevPath: `${location.pathname}${location.search ? location.search : ''}`,
      },
    });
    setReviewScenarioSelected({});
  };

  const scenarioSelectedIndex =
    useMemo(
      () =>
        roleplayView?.scenarios.findIndex(
          (scenario) => scenario.uuid === reviewScenarioSelected?.uuid
        ),
      [roleplayView, reviewScenarioSelected]
    ) || 0;

  return (
    <div className={styles.background}>
      <header className={styles.headerSection}>
        <div>
          <div style={{ display: 'flex', alignItems: 'center', gap: '1rem' }}>
            <button onClick={handleBack} className={styles.backBtn}>
              <img src={imageBasePath + 'back_arrow.svg'} alt="img" />
            </button>
            <p className="heading4">{roleplayView?.name || ''}</p>
            {reviewScenarioSelected?.response_retake !== null && (
              <RoleplayLabel {...roleplayView?.status.label} />
            )}
            {roleplayView?.scenarios?.length > 1 && (
              <div style={{ display: 'flex', alignItems: 'flex-end', gap: '0.25rem' }}>
                <p className="heading6">{`${scenarioSelectedIndex + 1 || 0}/${
                  roleplayView?.scenarios.length || 0
                }`}</p>
                <p className="supportText">Scenarios</p>
              </div>
            )}
          </div>
        </div>
        {reviewScenarioSelected.name !== 'Default' && <DropdownListScenarios />}
      </header>
      <body>
        {reviewScenarioSelected.name !== 'Default' ? (
          <>
            <p className="supportText" style={{ marginBottom: '1rem' }}>
              {reviewScenarioSelected?.description || roleplayView?.role_play.description}
            </p>
          </>
        ) : (
          <>
            <p className="supportText" style={{ marginBottom: '1rem' }}>
              {roleplayView?.role_play.description}
            </p>
          </>
        )}
        {reviewScenarioSelected?.response_retake !== null && (
          <div className={`${styles.listAttempts} heading6`}>
            <p
              className={secondAttempt ? '' : styles.selected}
              onClick={() => {
                setResponseRetake({});
                hideSecondAttempt();
              }}
            >
              1 Attempt
            </p>
            <p
              className={secondAttempt ? styles.selected : ''}
              onClick={() => {
                setResponseRetake(reviewScenarioSelected?.response_retake);
                showSecondAttempt();
              }}
              p
            >
              2 Attempt
            </p>
          </div>
        )}
        {reviewScenarioSelected?.response?.video && (
          <div
            style={{
              display: 'flex',
              alignContent: 'center',
              justifyContent: 'center',
              marginTop: '0.5rem',
              backgroundColor: '#e8ebed',
            }}
          >
            {!secondAttempt && <Video source={reviewScenarioSelected?.response.video} />}
            {secondAttempt && reviewScenarioSelected?.response_retake !== null && (
              <Video source={reviewScenarioSelected?.response_retake.video} />
            )}
          </div>
        )}
      </body>
    </div>
  );
};

export default ReviewRoleplayContent;
