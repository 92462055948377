import { useMemo, useEffect } from 'react';
import { useGetHomeDetails } from '../../../Stream.queries';
import { useHomeStore } from 'features/home/Home.stores';
import Organization from 'infrastructure/Organization';

const fetchDataChart = (data) => {
  const newData = data.categories.map((category) => {
    return {
      count: category.post_count || category.user_post_count,
      categoryName: category.category.name,
      categoryColor: category.category.color_code,
      categoryUuid: category.category.uuid,
      categoryPicture: category.category.picture_icon,
    };
  });
  newData.sort((a, b) => b.count - a.count);
  const keys = Object.keys(newData[0]);
  const keyObjects = {};
  keys.forEach((key) => {
    keyObjects[key] = newData.map((o) => o[key]);
  });
  return {
    labels: keyObjects.categoryName,
    datasets: [
      {
        label: '# Posts',
        data: keyObjects.count,
        backgroundColor: keyObjects.categoryColor,
        borderColor: keyObjects.categoryColor,
        borderWidth: 1,
      },
    ],
  };
};

const dataFake = {
  labels: ['Red'],
  datasets: [
    {
      label: '# of Votes',
      data: [1],
      backgroundColor: ['rgba(255, 99, 132, 0.2)'],
      borderColor: ['rgba(255, 99, 132, 1)'],
      borderWidth: 1,
    },
  ],
};

const HomeCardLogic = () => {
  const { selectedDateFilter } = useHomeStore();
  const {
    data = [],
    isLoading,
    refetch,
  } = useGetHomeDetails({
    time: Organization.FiltersEnabled(selectedDateFilter.slug),
  });
  useEffect(() => {
    refetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDateFilter]);

  const hasData = useMemo(() => (data?.categories || []).length > 0, [data]);

  const realData = useMemo(() => {
    if (!hasData) return dataFake;
    return fetchDataChart(data);
  }, [data, hasData]);

  const options = {
    plugins: {
      tooltip: {
        callbacks: {
          label: function (context) {
            return ` ${context?.label}: ${context?.formattedValue}`;
          },
        },
      },
      legend: {
        display: false,
      },
    },
    animation: {
      duration: 0,
    },
    maintainAspectRatio: false,
    cutout: 60,
    cutoutPercentage: 10,
    elements: {
      center: {
        text: 'Red is 2/3 of the total numbers',
        color: '#FF6384',
        fontStyle: 'Arial',
        sidePadding: 20,
        minFontSize: 25,
        lineHeight: 25,
      },
    },
  };

  return {
    options,
    data,
    isLoading,
    realData,
    hasData,
  };
};

export default HomeCardLogic;
