import { Route, Redirect, useLocation, useHistory } from 'react-router-dom';
import { useEffect } from 'react';
import { getStore } from '../store/GlobalStore';
import { roles } from '../constants';
import { shouldRedirectToPayment } from '../features/payment/Payment.utils';

export default function OrgnisationRouteCheck({ component: Component, path, ...rest }) {
  const history = useHistory();
  const { dispatch } = getStore();
  let location = useLocation();
  const userInfo = JSON.parse(localStorage.getItem('userInfo'));

  useEffect(() => {
    if (shouldRedirectToPayment({ role: getUserRole()?.slug })) history.push('/payment');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    let roleId = userInfo?.selectedOrg?.user_role;
    const userRole = roles().find((role) => role.id === roleId);
    dispatch({
      type: 'SET_ROLE',
      payload: userRole,
    });
    dispatch({
      type: 'SET_USER',
      payload: userInfo?.user,
    });
  }, [dispatch, userInfo?.selectedOrg?.user_role, userInfo?.user]);

  const isOrganizationPresent = () => {
    if (userInfo && userInfo.access && userInfo.selectedOrg && userInfo.organisations?.length) {
      return true;
    } else {
      localStorage.clear();
      localStorage.setItem('redirection', location.pathname + '' + location.search);
      return false;
    }
  };

  const getUserRole = () => {
    let roleId = userInfo?.selectedOrg?.user_role;
    const userRole = roles().find((role) => role.id === roleId);
    return userRole;
  };

  return (
    <Route
      {...rest}
      render={(props) =>
        isOrganizationPresent() ? (
          <>
            <Component role={getUserRole()} {...props} />
          </>
        ) : (
          <Redirect
            to={{
              pathname: '/signin',
            }}
          />
        )
      }
    />
  );
}
