import React, { Component } from 'react';
import { Container, Grid } from '@material-ui/core';
import styles from '../../../assets/styles/auth.module.scss';
import { InviteForm } from '../components';
import { CustomLink } from '../../../shared_ui_components';
import { getStore } from '../../../store/GlobalStore';
// import { GlobalContext } from '../../../context/GlobalContext';
import { regExpression, roles } from '../../../constants';
import { fieldValidation } from '../../../utils/formValidation';
import {
  acceptInvitation,
  socialSignUpApi,
  setPassword,
  handleSiginResponse,
} from '../apiServices';
import { toastFlashMessage } from '../../../utils';
import queryString from 'query-string';

class InviteUser extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showForm: false,
      formData: {
        first_name: '',
        last_name: '',
        email: '',
        password: '',
      },
      error: {},
      errorCode: {
        email: {
          0: '',
          1: 'Please enter email address',
          4: 'Invalid email address',
        },
        emailObj: {
          requiredFlag: true,
          regexPattern: regExpression.email,
        },
        password: {
          0: '',
          1: 'Please enter password',
        },
        passwordObj: {
          requiredFlag: true,
        },
        first_name: {
          0: '',
          1: 'Please enter first name',
        },
        firstNameObj: {
          requiredFlag: true,
        },
      },
      passwordValidation: {
        minCharacter: false,
        oneNumber: false,
        oneUpperCase: false,
        oneLowerCase: false,
      },
      submitLoader: false,
      socialLoginLoader: false,
      socialLinkedinLoader: false,
      organisations: [],
    };
    this.handleSiginResponse = handleSiginResponse.bind(this);
  }

  handleChange = (key, val) => {
    this.setState({
      formData: {
        ...this.state.formData,
        [key]: val,
      },
      error: {
        ...this.state.error,
        [key]: '',
      },
    });
    if (key == 'password') {
      this.setState({
        passwordValidation: {
          ...this.state.passwordValidation,
          minCharacter: this.hasLength(val),
          oneNumber: this.hasNumber(val),
          oneUpperCase: this.hasUpperCase(val),
          oneLowerCase: this.hasLowerCase(val),
        },
      });
    }
  };

  togglePassword = () => {
    this.setState({
      formData: {
        ...this.state.formData,
        password_show: !this.state.formData.password_show,
      },
    });
  };

  componentDidMount() {
    let base_url = window.location.origin;
    const querys = queryString.parse(this.props.location.search);
    const token = querys.token;
    let email = querys.email;
    const orgSlug = querys.org_slug;
    const google_access_token = queryString.parse(this.props.location.hash)
      ? queryString.parse(this.props.location.hash).access_token
      : '';
    const linkedIn_token = querys.code;
    if (google_access_token) {
      localStorage.setItem('userInfo', JSON.stringify({ access: token }));
      this.setState((prevState) => ({
        ...prevState,
        socialLoginLoader: true,
      }));
      setTimeout(() => {
        socialSignUpApi({
          access_token: google_access_token,
          social_type: 'google',
        }).then((response) => {
          this.handleSiginResponse(response);
        });
      }, 300);
    } else if (linkedIn_token) {
      localStorage.setItem('userInfo', JSON.stringify({ access: token }));
      this.setState((prevState) => ({
        ...prevState,
        socialLinkedinLoader: true,
      }));
      setTimeout(() => {
        socialSignUpApi({
          access_token: linkedIn_token,
          social_type: 'linkedin',
          redirect_uri: `${base_url}/signin`,
        }).then((response) => {
          this.handleSiginResponse(response);
        });
      }, 300);
    } else if (token && email && orgSlug) {
      if (email) {
        email = email.replace(/\s/g, '+');
      }
      this.setState((prevState) => ({
        ...prevState,
        formData: {
          ...prevState.formData,
          token: token,
          email: email,
          org_slug: orgSlug,
        },
      }));
      setTimeout(() => {
        acceptInvitation({
          token: token,
          email: email,
          org_slug: orgSlug,
        }).then((response) => {
          if (response.statusCode === 200) {
            const { dispatch } = getStore();
            if (response.data.existing_user) {
              const selected = response.data.organisations.filter(
                ({ selected }) => selected === true
              );
              let responseUpdated = {
                ...response.data,
                selectedOrg: selected && selected.length ? selected[0] : {},
              };
              let roleId = responseUpdated.selectedOrg.user_role;
              const userRole = roles().find((role) => role.id === roleId);
              dispatch({
                type: 'SET_ROLE',
                payload: userRole,
              });
              dispatch({
                type: 'SET_USER',
                payload: responseUpdated.user,
              });
              localStorage.setItem('userInfo', JSON.stringify(responseUpdated));
              this.props.history.push('/');
            } else {
              this.setState((prevState) => ({
                ...prevState,
                showForm: true,
              }));
              localStorage.clear();
            }
          } else {
            toastFlashMessage(response.message, 'error');
            localStorage.clear();
            this.props.history.push('/');
          }
        });
      }, 5000);
    } else {
      this.setState({
        submitLoader: false,
      });
    }
  }

  hasNumber = (str) => {
    return /\d/.test(str);
  };

  hasLength = (str) => {
    if (str.length > 7) {
      return true;
    } else {
      return false;
    }
  };

  hasLowerCase = (str) => {
    return /[a-z]/.test(str);
  };

  hasUpperCase = (str) => {
    return /[A-Z]/.test(str);
  };

  handleSubmit = () => {
    const { errorCode, formData, passwordValidation } = this.state;
    let validateNewInput = {
      first_name:
        errorCode['first_name'][
          fieldValidation({
            ...errorCode['firstNameObj'],
            fieldval: formData.first_name,
          })
        ],
      email:
        errorCode['email'][
          fieldValidation({
            ...errorCode['emailObj'],
            fieldval: formData.email,
          })
        ],
      password:
        errorCode['password'][
          fieldValidation({
            ...errorCode['passwordObj'],
            fieldval: formData.password,
          })
        ],
    };
    if (validateNewInput.password === '') {
      validateNewInput = {
        ...validateNewInput,
        password: Object.values(passwordValidation).every((k) => {
          return k === true;
        })
          ? ''
          : 'Pattern not matching',
      };
    }
    if (Object.keys(validateNewInput).every((k) => validateNewInput[k] == '')) {
      this.setState({
        submitLoader: true,
      });
      setPassword({
        first_name: formData.first_name,
        last_name: formData.last_name,
        token: formData.token,
        email: formData.email,
        org_slug: formData.org_slug,
        password: formData.password,
      }).then((response) => {
        this.setState({
          submitLoader: false,
        });
        if (response.statusCode === 200) {
          const { dispatch } = getStore();
          let responseUpdated = {
            ...response.data,
            selectedOrg: response.data.organisations[0],
          };
          let roleId = responseUpdated.selectedOrg.user_role;
          const userRole = roles().find((role) => role.id === roleId);
          dispatch({
            type: 'SET_ROLE',
            payload: userRole,
          });
          dispatch({
            type: 'SET_USER',
            payload: responseUpdated.user,
          });
          localStorage.setItem('userInfo', JSON.stringify(responseUpdated));
          this.props.history.push('/');
          toastFlashMessage(response.message, 'success');
        } else {
          toastFlashMessage(response.message, 'error');
        }
      });
    } else {
      this.setState({
        error: validateNewInput,
      });
    }
  };

  render() {
    const {
      formData,
      error,
      submitLoader,
      passwordValidation,
      socialLoginLoader,
      socialLinkedinLoader,
      showForm,
    } = this.state;
    return (
      <div className={styles.signInPage + ' ' + styles.invitePage}>
        <section className={styles.signInSection + ' backgroundProp'}>
          <div className={styles.signInWrapper}>
            <Grid container direction="row" alignItems="center" className={styles.signInRow}>
              <Grid item lg={12} md={12} sm={12} xs={12}>
                {showForm ? (
                  <InviteForm
                    formData={formData}
                    error={error}
                    submitLoader={submitLoader}
                    socialLoginLoader={socialLoginLoader}
                    socialLinkedinLoader={socialLinkedinLoader}
                    handleChange={this.handleChange}
                    handleSubmit={this.handleSubmit}
                    togglePassword={this.togglePassword}
                    passwordValidation={passwordValidation}
                  />
                ) : (
                  <p className="paragraph textCenter">Please wait...</p>
                )}
              </Grid>
            </Grid>
          </div>
        </section>
      </div>
    );
  }
}

export default InviteUser;
