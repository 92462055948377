import { useState, useEffect } from 'react';
import { Grid } from '@material-ui/core';
import { imageBasePath } from '../../../constants';
import { ModuleInit, TrackingProgress, TrainingProgress, UserDetailsHeader, Shimmer } from './';
import styles from '../../../assets/styles/home.module.scss';
import {
  getOnboardingOverviewApi,
  getTrainingOverviewApi,
  getTrackingOverviewApi,
  getUserPreferencesApi,
} from '../apiServices';
import HomeIntroductionCard from './HomeIntroductionCard';
import { CheckinDrawer } from '../../tracking/components';
import { toastFlashMessage } from '../../../utils';
import { checkinUpdateApi, checkinEditApi, getCurrentCheckinApi } from '../../tracking/apiServices';
import HomeCard from 'features/stream/UI/Analytics/HomeCard/HomeCard';
import { FeatureFlag, Subscription } from 'controllers/_exports';
import RoleplayHome from 'features/roleplay/Components/RoleplayHome/RoleplayHome';
import { useHomeStore } from '../Home.stores';
import Organization from 'infrastructure/Organization';

export default function UserHomeContainer(props) {
  const [onboarding, setOnboarding] = useState('');
  const [training, setTraining] = useState('');
  const [tracking, setTracking] = useState('');
  const [loader, setLoader] = useState({
    onboarding: true,
    training: true,
    tracking: true,
    userPreferences: true,
  });
  const [checkinLoader, checkinLoaderSet] = useState(false);
  const [userPreferences, setUserPreferences] = useState({});
  const [checkinOpen, setCheckinOpen] = useState(false);
  const [latestCheckin, latestCheckinSet] = useState(false);
  const [isEdit, isEditSet] = useState(false);
  const [isEditLoader, isEditLoaderSet] = useState(false);
  const [currentEditCheckin, currentEditCheckinSet] = useState(false);
  const [salesGoals, salesGoalsSet] = useState({});
  const [latestEditCheckin, latestEditCheckinSet] = useState(false);
  const { selectedDateFilter } = useHomeStore();

  useEffect(() => {
    getUserPreferencesApi().then((response) => {
      if (response.statusCode === 200) {
        setLoader((prevLoader) => ({
          ...prevLoader,
          userPreferences: false,
        }));
        setUserPreferences(response.data);
      }
    });
  }, []);
  const fetchTrackingOverviewApi = () => {
    getTrackingOverviewApi().then((response) => {
      if (response.statusCode === 200) {
        setLoader((prevLoader) => ({
          ...prevLoader,
          tracking: false,
        }));
        setTracking(response.data);
        latestCheckinSet(response.data.checkin);
        let salesGoals = response.data.checkin.categories.find((cat) => !cat.is_organisation);
        salesGoalsSet(salesGoals);
        response.data.checkin.checkin_exists ? isEditSet(true) : isEditSet(false);
      }
    });
  };
  useEffect(() => {
    fetchTrackingOverviewApi();
  }, []);

  useEffect(() => {
    setLoader((prevLoader) => ({
      ...prevLoader,
      onboarding: true,
    }));
    getOnboardingOverviewApi({
      time: Organization.FiltersEnabled(selectedDateFilter.slug),
    }).then((response) => {
      if (response.statusCode === 200) {
        setLoader((prevLoader) => ({
          ...prevLoader,
          onboarding: false,
        }));
        setOnboarding(response.data);
      }
    });
  }, [selectedDateFilter]);

  useEffect(() => {
    setLoader((prevLoader) => ({
      ...prevLoader,
      training: true,
    }));
    getTrainingOverviewApi({
      time: Organization.FiltersEnabled(selectedDateFilter.slug),
    }).then((response) => {
      if (response.statusCode === 200) {
        setLoader((prevLoader) => ({
          ...prevLoader,
          training: false,
        }));
        setTraining(response.data);
      }
    });
  }, [selectedDateFilter]);
  const updateCheckin = ({ data, date, isEdit, onSuccess }) => {
    if (isEdit) {
      checkinEditApi(data, date)
        .then((response) => {
          if (response.statusCode === 200) {
            setCheckinOpen(false);
            // fetchCurrentCheckin();
            fetchTrackingOverviewApi();
            toastFlashMessage(response.message, 'success');
            currentEditCheckinSet(false);
            latestEditCheckinSet(false);
            latestCheckin.checkin_exists ? isEditSet(true) : isEditSet(false);
            onSuccess();
          } else {
            checkinLoaderSet(false);
            toastFlashMessage(response.message, 'error');
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      checkinUpdateApi(data, date)
        .then((response) => {
          if (response.statusCode === 200) {
            setCheckinOpen(false);
            fetchTrackingOverviewApi();
            toastFlashMessage(response.message, 'success');
            latestCheckin.checkin_exists ? isEditSet(true) : isEditSet(false);
            onSuccess();
          } else {
            checkinLoaderSet(false);
            toastFlashMessage(response.message, 'error');
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  const hanldeEditCheckin = (checkin, is_current_edit = false) => {
    isEditLoaderSet(true);
    isEditSet(true);
    fetchCurrentEditCheckin(checkin, is_current_edit);
    setCheckinOpen(true);
  };

  const fetchCurrentEditCheckin = (checkin, is_current_edit) => {
    getCurrentCheckinApi(!is_current_edit ? { date: checkin } : null).then((response) => {
      if (response.statusCode === 200) {
        isEditLoaderSet(false);
        currentEditCheckinSet(response.data.categories);
        latestEditCheckinSet({ ...response.data, checkin_date: checkin });
      } else {
        toastFlashMessage(response.message, 'error');
      }
    });
  };

  return (
    <>
      {loader &&
      (loader.tracking || loader.onboarding || loader.training || loader.userPreferences) ? (
        <Shimmer type="UserHome" />
      ) : (
        <>
          {onboarding &&
          (onboarding?.completed_steps == onboarding?.total_steps ||
            userPreferences?.onboarding?.completed) &&
          tracking &&
          tracking.published_at &&
          training.progress ? (
            <div className={styles.homePageContent}>
              <section className={styles.homePageProgressSectioin}>
                <UserDetailsHeader />
                <div className={styles.progressContent}>
                  <Grid container spacing={3}>
                    <Grid item xs={12} md={6}>
                      <TrackingProgress
                        tracking={tracking}
                        handleOpenCheckin={(flag) => setCheckinOpen(flag)}
                        hanldeEditCheckin={hanldeEditCheckin}
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <TrainingProgress training={training} />
                    </Grid>
                    {FeatureFlag.enabled('STREAM') &&
                      Subscription.allow({ key: Subscription.keys.stream, openModal: false }) && (
                        <Grid item xs={12} md={6}>
                          <HomeCard user="user" />
                        </Grid>
                      )}
                    <RoleplayHome />
                    <RoleplayHome graph />
                  </Grid>
                </div>
              </section>
            </div>
          ) : (
            <>
              <HomeIntroductionCard role={'user'} />
              <div className={styles.pageTitle + ''}>
                <h3 className={`${styles.pageTitle} heading4`}>
                  <span>Go ahead and let's do it</span>
                </h3>
              </div>
              <div className={styles.homePageContent}>
                <Grid container spacing={3}>
                  <Grid item xs={12} md={6}>
                    {onboarding ? (
                      <ModuleInit
                        moduleData={{
                          featuredImg: `${imageBasePath}onboarding_init_featured.svg`,
                          icon: `${imageBasePath}onboard_default.svg`,
                          title: `Onboarding`,
                          description: `Get to know your company's mission, vision, and values`,
                          progress: {
                            completed: onboarding?.completed_steps,
                            total: onboarding?.total_steps,
                          },
                          type: 'onboarding',
                          link: '/onboarding',
                        }}
                        disabled={onboarding.published_at ? false : true}
                      />
                    ) : (
                      <p className="paragraph">Loading...</p>
                    )}
                  </Grid>
                  <Grid item xs={12} md={6}>
                    {training ? (
                      training.completed_moduels <= training.total_modules ||
                      training.is_published === false ? (
                        <ModuleInit
                          moduleData={{
                            featuredImg: `${imageBasePath}training_init_featured.svg`,
                            icon: `${imageBasePath}training_default.svg`,
                            title: `Training`,
                            description: `Get to know the products and services your company has to offer`,
                            progress: {
                              completed: training.completed_moduels,
                              total: training.total_modules,
                            },
                            link: '/training',
                          }}
                          type="training"
                          disabled={
                            training.is_published === false ||
                            (userPreferences?.onboarding?.required &&
                              !userPreferences?.onboarding?.completed)
                              ? true
                              : false
                          }
                        />
                      ) : null
                    ) : (
                      <p className="paragraph">Loading...</p>
                    )}
                  </Grid>
                  <RoleplayHome />
                  <RoleplayHome graph />
                </Grid>
              </div>
            </>
          )}
        </>
      )}

      <CheckinDrawer
        open={checkinOpen}
        handleClose={() => {
          latestCheckin.checkin_exists ? isEditSet(true) : isEditSet(false);
          currentEditCheckinSet(false);
          setCheckinOpen(false);
          checkinLoaderSet(false);
        }}
        currentCheckin={isEdit ? currentEditCheckin : tracking?.checkin?.categories}
        updateCheckin={updateCheckin}
        role={{ slug: `user` }}
        isEdit={isEdit}
        isEditLoader={isEditLoader}
        latestEditCheckin={latestEditCheckin}
        latestCheckin={latestCheckin}
        checkinLoaderSet={checkinLoaderSet}
        checkinLoader={checkinLoader}
      />
    </>
  );
}
