import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { FeatureFlag } from 'controllers/_exports';
import { useSalesRepView } from '../SalesRepView.hook';

const SalesRepViewHandler = () => {
  const history = useHistory();
  const { isPathSupported, isActive } = useSalesRepView({ requestValue: true });

  // Handle unsupported paths
  useEffect(() => {
    if (!FeatureFlag.enabled('SALES_REP_POV')) return;
    if (!isPathSupported && isActive) history.push('/onboarding');
  }, [isPathSupported, isActive, history]);

  return null;
};

export default SalesRepViewHandler;
