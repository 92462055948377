import { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Grid, Avatar, Menu, MenuItem } from '@material-ui/core';
import styles from '../../../assets/styles/users.module.scss';
import { imageBasePath, roles } from '../../../constants';
import { format, parseISO } from 'date-fns';
import {
  updatedUserRoleApi,
  deactivateUserApi,
  removeInvitationApi,
  resendInvitationApi,
  reactivateUserApi,
  transferOwnerShip,
  getInvitationLink,
} from '../apiServices';
import { toastFlashMessage } from '../../../utils';
import queryString from 'query-string';
import { ConfirmationDialog } from '../../../shared_elements';
import { FeatureFlag } from 'controllers/_exports';
import { decreaseSubscriptionUsers } from 'features/payment/Payment.utils';
import { useUsersStore } from '../Users.store';
import SilentModeIcon from './Settings/SilentModeIcon';

export default function UserItem(props) {
  const location = useLocation();
  const { user, index, role, currentUser, userRole } = props;
  const [anchorEl, setAnchorEl] = useState(null);
  const [openConfirm, setOpenConfirm] = useState(false);
  const [newRole, setNewRole] = useState('');
  const [loader, setLoader] = useState(false);
  const [deactivate, setDeactivate] = useState(false);
  const [removeInvite, setRemoveInvite] = useState(false);
  const [resendInvite, setResendInvite] = useState(false);
  const [reactivate, setReactivate] = useState(false);
  const userInfo = JSON.parse(localStorage.getItem('userInfo'));

  const handleClick = (event, key) => {
    setAnchorEl(event.currentTarget);
  };
  const handleUpdateUserRole = (role) => {
    setLoader(true);
    if (role == 1) {
      transferOwnerShip(user?.user?.uuid, {}).then((response) => {
        setLoader(false);
        if (response.statusCode == 200) {
          setOpenConfirm(false);
          setNewRole('');
          const querys = queryString.parse(location.search, { decode: true });
          props.fetchAllUsers({ page: 1, ...querys });
          toastFlashMessage(response.message, 'success');
        } else {
          toastFlashMessage(response.message, 'error');
        }
      });
    } else {
      updatedUserRoleApi(user?.user?.uuid, { role }).then((response) => {
        setLoader(false);
        if (response.statusCode == 200) {
          setOpenConfirm(false);
          setNewRole('');
          const querys = queryString.parse(location.search, { decode: true });
          props.fetchAllUsers({ page: 1, ...querys });
          toastFlashMessage(response.message, 'success');
        } else {
          toastFlashMessage(response.message, 'error');
        }
      });
    }
  };
  const isLoggedinUser = (uuid) => {
    const loggedInUser = localStorage.getItem('userInfo')
      ? JSON.parse(localStorage.getItem('userInfo')).user
      : '';
    if (loggedInUser && loggedInUser?.uuid === uuid) {
      return true;
    } else {
      return false;
    }
  };
  const handleDeactivateUser = () => {
    setLoader(true);
    deactivateUserApi(user?.user?.uuid, {}).then((response) => {
      setLoader(false);
      if (response.statusCode == 200) {
        setDeactivate(false);
        const querys = queryString.parse(location.search, { decode: true });
        props.fetchAllUsers({ page: 1, ...querys });
        toastFlashMessage(response.message, 'success');
      } else {
        toastFlashMessage(response.message, 'error');
      }
    });
  };
  const handleReactivateUser = () => {
    setLoader(true);
    reactivateUserApi(user?.user?.uuid, {}).then((response) => {
      setLoader(false);
      if (response.statusCode == 200) {
        setReactivate(false);
        const querys = queryString.parse(location.search, { decode: true });
        props.fetchAllUsers({ page: 1, ...querys });
        toastFlashMessage(response.message, 'success');
      } else {
        toastFlashMessage(response.message, 'error');
      }
    });
  };
  const handleRemoveInvitation = () => {
    setLoader(true);
    removeInvitationApi(user?.user?.uuid, {}).then((response) => {
      setLoader(false);
      if (response.statusCode == 200) {
        setRemoveInvite(false);
        decreaseSubscriptionUsers();
        const querys = queryString.parse(location.search, { decode: true });
        props.fetchAllUsers({ page: 1, ...querys });
        toastFlashMessage(response.message, 'success');
      } else {
        toastFlashMessage(response.message, 'error');
      }
    });
  };
  const handleResendInvitation = () => {
    setLoader(true);
    resendInvitationApi(user?.user?.uuid, {}).then((response) => {
      setLoader(false);
      if (response.statusCode == 200) {
        setResendInvite(false);
        const querys = queryString.parse(location.search, { decode: true });
        props.fetchAllUsers({ page: 1, ...querys });
        toastFlashMessage(response.message, 'success');
      } else {
        toastFlashMessage(response.message, 'error');
      }
    });
  };
  const handleCopyInvitationLink = () => {
    setLoader(true);
    getInvitationLink(user?.user?.uuid).then((response) => {
      setLoader(false);
      if (response.statusCode == 200) {
        navigator.clipboard.writeText(response.data.invite_link);
        toastFlashMessage('Invitation Link Copied to Clipboard', 'success');
      } else {
        toastFlashMessage(response.message, 'error');
      }
    });
  };

  // User settings
  const setUserDataSettings = useUsersStore((s) => s?.setUserData);
  const handleUserSettings = (e) => {
    setUserDataSettings(user);
    e.preventDefault();
    e.stopPropagation();
    setAnchorEl(null);
  };

  const showSettings =
    !isLoggedinUser(user?.user?.uuid) &&
    user?.role === 'Sales Rep' &&
    ['admin', 'primary_admin', 'sales_lead'].includes(role);

  return (
    <>
      <Grid
        container
        direction="row"
        spacing={2}
        className={`${styles.usersRow} ${user.status == 'Deactivated' ? styles.disabledUsers : ''}`}
      >
        <Grid item xs={7}>
          <Grid container direction="row" spacing={2}>
            <Grid item xs={4}>
              {user?.user?.profile_pic ? (
                <img className={styles.usersImg} src={user?.user?.profile_pic} alt="User" />
              ) : (
                <Avatar className={styles.avatarFallback + ' avatar '}>
                  {user?.user?.first_name
                    ? user?.user?.first_name[0]
                    : user?.user.email
                    ? user?.user.email[0]
                    : 'U'}
                </Avatar>
              )}
              <div className={styles.usersCustomParagraph + ' paragraph'}>
                <span style={{ marginRight: '8px' }}>
                  {user?.user?.first_name || user?.user?.last_name
                    ? `${user?.user?.first_name ? user?.user?.first_name : ''} ${
                        user?.user?.last_name ? user?.user?.last_name : ''
                      }`
                    : '--'}
                </span>

                {showSettings && (
                  <img
                    className={styles.userSettingsBtn}
                    src={imageBasePath + 'settings.svg'}
                    alt="Settings"
                    onClick={handleUserSettings}
                  />
                )}
              </div>
            </Grid>
            <Grid item xs={5}>
              <p className={styles.usersCustomParagraph + ' paragraph '}>
                {user?.user.email} <SilentModeIcon user={user} />
              </p>
            </Grid>
            <Grid item xs={3}>
              <p className={styles.usersCustomParagraph + ' paragraph '}>
                {currentUser ? userRole.label : roles()[user.user_role - 1].label}
              </p>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={5}>
          <Grid container direction="row" spacing={2}>
            <Grid item xs={5}>
              <p className={styles.usersCustomParagraph + ' paragraph '}>
                {user.user?.timezone && user.user?.timezone !== 'undefined'
                  ? user.user?.timezone
                  : `--`}
              </p>
            </Grid>
            <Grid item xs={3}>
              <p className={styles.usersCustomParagraph + ' paragraph '}>
                {currentUser && userInfo?.selectedOrg?.user_joined_at
                  ? format(parseISO(userInfo.selectedOrg.user_joined_at), 'MM/dd/yyyy')
                  : user.joined_at
                  ? format(parseISO(user.joined_at), 'MM/dd/yyyy')
                  : '--'}
              </p>
            </Grid>
            <Grid item xs={4}>
              <div className={styles.usersMoreActionWrap}>
                <div className={styles.userStatusWrap}>
                  <span
                    className={
                      styles.usersStatus +
                      ' paragraph ' +
                      styles[currentUser ? 'Active' : user.status]
                    }
                  >
                    {currentUser ? 'Active' : user.status}
                  </span>
                </div>
                {['primary_admin', 'admin'].includes(role) &&
                user.user_role != 1 &&
                !isLoggedinUser(user?.user?.uuid) ? (
                  <img
                    className={styles.usersMoreAction}
                    src={imageBasePath + 'more_vertical_gray.svg	'}
                    alt="More Action"
                    onClick={(e) => {
                      e.stopPropagation();
                      e.preventDefault();
                      handleClick(e);
                    }}
                  />
                ) : null}
                <Menu
                  id={'simple-menu-' + index}
                  anchorEl={anchorEl}
                  keepMounted
                  open={Boolean(anchorEl) ? true : false}
                  onClose={(e) => {
                    setAnchorEl(null);
                    e.preventDefault();
                    e.stopPropagation();
                  }}
                  className="moreMenu"
                >
                  {user.status == 'Deactivated' ? (
                    <MenuItem
                      onClick={(e) => {
                        e.stopPropagation();
                        setAnchorEl(null);
                        setReactivate(true);
                      }}
                    >
                      <span className="supportText">{`Reactivate`}</span>
                    </MenuItem>
                  ) : (
                    <>
                      {user.user_role === 2 && user.status == 'Active' ? (
                        <>
                          {role == 'primary_admin' && (
                            <MenuItem
                              onClick={(e) => {
                                e.preventDefault();
                                e.stopPropagation();
                                setAnchorEl(null);
                                // handleUpdateUserRole(2)
                                setOpenConfirm(true);
                                setNewRole(1);
                              }}
                            >
                              <span className="supportText">Change to Owner</span>
                            </MenuItem>
                          )}
                          <MenuItem
                            onClick={(e) => {
                              e.preventDefault();
                              e.stopPropagation();
                              setAnchorEl(null);
                              // handleUpdateUserRole(3)
                              setOpenConfirm(true);
                              setNewRole(3);
                            }}
                          >
                            <span className="supportText">{`Change to Sales ${FeatureFlag.enabled("RENAME_SALES_LEAD") ? 'Management role' : 'Lead'}`}</span>
                          </MenuItem>
                          <MenuItem
                            onClick={(e) => {
                              e.preventDefault();
                              e.stopPropagation();
                              setAnchorEl(null);
                              // handleUpdateUserRole(4)
                              setOpenConfirm(true);
                              setNewRole(4);
                            }}
                          >
                            <span className="supportText">Change to Sales Rep</span>
                          </MenuItem>
                        </>
                      ) : user.user_role === 3 && user.status == 'Active' ? (
                        <>
                          {role == 'primary_admin' && (
                            <>
                              <MenuItem
                                onClick={(e) => {
                                  e.preventDefault();
                                  e.stopPropagation();
                                  setAnchorEl(null);
                                  // handleUpdateUserRole(2)
                                  setOpenConfirm(true);
                                  setNewRole(1);
                                }}
                              >
                                <span className="supportText">Change to Owner</span>
                              </MenuItem>
                            </>
                          )}
                          <MenuItem
                            onClick={(e) => {
                              e.preventDefault();
                              e.stopPropagation();
                              setAnchorEl(null);
                              // handleUpdateUserRole(2)
                              setOpenConfirm(true);
                              setNewRole(2);
                            }}
                          >
                            <span className="supportText">Change to Admin</span>
                          </MenuItem>
                          <MenuItem
                            onClick={(e) => {
                              e.preventDefault();
                              e.stopPropagation();
                              setAnchorEl(null);
                              // handleUpdateUserRole(4)
                              setOpenConfirm(true);
                              setNewRole(4);
                            }}
                          >
                            <span className="supportText">Change to Sales Rep</span>
                          </MenuItem>
                        </>
                      ) : (
                        <>
                          {role == 'primary_admin' && user.status == 'Active' && (
                            <>
                              <MenuItem
                                onClick={(e) => {
                                  e.preventDefault();
                                  e.stopPropagation();
                                  setAnchorEl(null);
                                  // handleUpdateUserRole(2)
                                  setOpenConfirm(true);
                                  setNewRole(1);
                                }}
                              >
                                <span className="supportText">Change to Owner</span>
                              </MenuItem>
                            </>
                          )}
                          {showSettings && (
                            <MenuItem onClick={handleUserSettings}>
                              <span className="supportText">Settings</span>
                            </MenuItem>
                          )}
                          {user.status == 'Active' && (
                            <>
                              <MenuItem
                                onClick={(e) => {
                                  e.preventDefault();
                                  e.stopPropagation();
                                  setAnchorEl(null);
                                  // handleUpdateUserRole(2)
                                  setOpenConfirm(true);
                                  setNewRole(2);
                                }}
                              >
                                <span className="supportText">Change to Admin</span>
                              </MenuItem>
                              <MenuItem
                                onClick={(e) => {
                                  e.preventDefault();
                                  e.stopPropagation();
                                  setAnchorEl(null);
                                  // handleUpdateUserRole(3)
                                  setOpenConfirm(true);
                                  setNewRole(3);
                                }}
                              >
                                <span className="supportText">{`Change to Sales ${FeatureFlag.enabled("RENAME_SALES_LEAD") ? 'Management role' : 'Lead'}`}</span>
                              </MenuItem>
                            </>
                          )}
                        </>
                      )}
                      {user.status == `Invited` ? (
                        <MenuItem
                          onClick={(e) => {
                            e.stopPropagation();
                            setAnchorEl(null);
                            setResendInvite(true);
                          }}
                        >
                          <span className="supportText">{`Resend Invitation`}</span>
                        </MenuItem>
                      ) : null}
                      {!(user.user_role == 2 && role == 'admin') && (
                        <MenuItem
                          onClick={(e) => {
                            e.stopPropagation();
                            setAnchorEl(null);
                            if (user.status == `Invited`) {
                              setRemoveInvite(true);
                            } else {
                              setDeactivate(true);
                            }
                          }}
                        >
                          <span className="supportText">
                            {user.status == `Invited` ? `Remove` : `Deactivate`}
                          </span>
                        </MenuItem>
                      )}
                      {user.status == `Invited` && FeatureFlag.enabled('COPY_INVITATION_LINK') && (
                        <MenuItem
                          onClick={(e) => {
                            e.stopPropagation();
                            setAnchorEl(null);
                            handleCopyInvitationLink();
                          }}
                        >
                          <span className="supportText">Copy invitation link</span>
                        </MenuItem>
                      )}
                    </>
                  )}
                </Menu>
              </div>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      {openConfirm || deactivate || resendInvite || removeInvite || reactivate ? (
        <ConfirmationDialog
          open={true}
          title={
            reactivate ? (
              <>
                Are you sure you want to <strong>reactivate</strong> this user?
              </>
            ) : resendInvite ? (
              <>
                Are you sure you want to <strong>resend</strong> the invitation ?
              </>
            ) : removeInvite ? (
              <>
                Are you sure you want to <strong>remove</strong> the invitation ?{' '}
              </>
            ) : deactivate ? (
              <>
                Are you sure you want to <strong>deactivate</strong> this user?
              </>
            ) : (
              <>
                Are you sure you want to change the access role from{' '}
                <strong>{roles()[user.user_role - 1].label} </strong> to{' '}
                <strong>{newRole ? roles().find((role) => role.id == newRole).label : ''}</strong> role?
              </>
            )
          }
          description={
            resendInvite || deactivate || reactivate
              ? null
              : `${
                  newRole == 1
                    ? "Your ownership will be transferred and you no longer will be owner of this organization. This action can't be undone"
                    : "This action can't be undone"
                }`
          }
          acceptText={
            resendInvite
              ? 'Yes, Resend'
              : removeInvite
              ? 'Yes, Remove'
              : deactivate
              ? `Yes, Deactivate`
              : reactivate
              ? `Yes, Reactivate`
              : `Yes, Change`
          }
          rejectText="No, Cancel"
          image={`${imageBasePath}${
            deactivate
              ? `deactivate_user_img.svg`
              : openConfirm
              ? `reactivate_user_img.svg`
              : resendInvite
              ? `resend_invitation_img.svg`
              : removeInvite
              ? `remove_invitation_img.svg`
              : reactivate
              ? `reactivate_user_img.svg`
              : 'published_logo.png'
          }`}
          handleClose={() => {
            setOpenConfirm(false);
            setNewRole('');
            setDeactivate(false);
            setRemoveInvite(false);
            setResendInvite(false);
            setReactivate(false);
          }}
          handleSubmit={() => {
            if (openConfirm) {
              handleUpdateUserRole(newRole);
            } else if (deactivate) {
              handleDeactivateUser();
            } else if (resendInvite) {
              handleResendInvitation();
            } else if (reactivate) {
              handleReactivateUser();
            } else {
              handleRemoveInvitation();
            }
          }}
          loader={loader}
        />
      ) : null}
    </>
  );
}
