import React, { useState } from 'react';
import { Grid } from '@material-ui/core';
import { imageBasePath } from '../../../constants';
import styles from '../../../assets/styles/onboarding.module.scss';
import { ProgressBar } from '../../../shared_ui_components';
import { FeatureFlag } from 'controllers/_exports';

export default function SetupOnboarding(props) {
  const { totalSteps, attendedSteps } = props;
  const [note, setNote] = useState(true);
  const closeNote = () => {
    setNote(false);
  };
  return (
    <>
      {note ? (
        <div className="note-block">
          <p className="pararaph">
            <img src={imageBasePath + 'info_darkgrey.svg'} alt="info" /> 
            {`Make sure to add all content under enabled steps in order to publish. Kindly note that without publishing, sales ${FeatureFlag.enabled("RENAME_SALES_LEAD") ? 'management roles' : 'leads'} and sales rep won't be able to access content`}
          </p>
          <a onClick={() => closeNote()} className="closeNote">
            <img src={imageBasePath + 'close.svg'} alt="info" />
          </a>
        </div>
      ) : null}
      <section className={styles.setupOnboardingsection}>
        <Grid container direction="row" alignItems="center" spacing={2}>
          <Grid item lg={6} md={6} sm={12} xs={12}>
            <div className={styles.titleWrapper}>
              <div className={styles.imgBlk}>
                <img src={imageBasePath + 'company_default.svg'} alt="Company" />
              </div>
              <div className={styles.contentBlk}>
                <h1 className="heading3">Setup Onboarding</h1>
                <p className="supportText">
                  Integrating a new sales colleague into your company is critical. Build checklists
                  with your company history, vision and culture to help them be productive
                </p>
              </div>
            </div>
          </Grid>
          <Grid item lg={6} md={6} sm={12} xs={12}>
            <div className={styles.stepsWrapper}>
              <Grid container direction="row" alignItems="center" spacing={1}>
                <Grid item lg={6} md={6} sm={12} xs={12}>
                  <div className={styles.progressBlk}>
                    <ProgressBar
                      value={attendedSteps / totalSteps}
                      className={styles.stepProgress + ' stepProgress'}
                    />
                  </div>
                </Grid>
                <Grid item lg={6} md={6} sm={12} xs={12}>
                  <div className={styles.stepsBlk}>
                    <h2 className="heading3">
                      <span
                        className={styles.steps + ' heading3'}
                      >{`${attendedSteps}/${totalSteps}`}</span>
                      <span className={styles.supportText + ' supportText'}>
                        {' '}
                        Steps are completed
                      </span>
                    </h2>
                  </div>
                </Grid>
              </Grid>
            </div>
          </Grid>
        </Grid>
        <img
          className={styles.patternBg}
          src={imageBasePath + 'onboarding_setup_pattern.svg'}
          alt="Image"
        />
      </section>
    </>
  );
}
