import React, { useState } from 'react';
import styles from './TeamPulse.module.scss';
import { FeatureFlag } from 'controllers/_exports';
import Icon from '../../components/Icon/Icon';
import { useGetHomeDetails } from '../../../Stream.queries';
import Organization from 'infrastructure/Organization';
import { useStreamStore } from 'features/stream/Stream.store';

const TeamPulse = () => {
  const [selectedTab, setSelectedTab] = useState(false);
  const { selectedDateFilterPulse } = useStreamStore();
  const { data = {} } = useGetHomeDetails({
    time: Organization.FiltersEnabled(selectedDateFilterPulse.slug),
  });
  const { categories = {} } = data;

  return (
    <div className={styles.teamPulseContainer}>
      {FeatureFlag.enabled('STREAM_IMPROVEMENTS') ? (
        <>
          <div className={styles.sectionSelector}>
            <span
              className={`${selectedTab === false ? styles.selected : ''}`}
              onClick={() => setSelectedTab(false)}
            >
              My activity
            </span>
            <span
              className={`${selectedTab === true ? styles.selected : ''}`}
              onClick={() => setSelectedTab(true)}
            >
              Team activity
            </span>
          </div>
        </>
      ) : (
        <>
          <h1 className={styles.title}>{Organization.isRep() ? 'My activity' : 'Team pulse'}</h1>
        </>
      )}

      <div className={styles.dataContainer}>
        {Object.values(categories).map(({ category, post_count, user_post_count }) => (
          <div key={category.uuid}>
            <Icon src={category.picture_icon} color={category.color_code} />
            <div className={styles.countSection}>
              <div className={styles.number}>
                {FeatureFlag.enabled('STREAM_IMPROVEMENTS') ? (
                  <>
                    {(selectedTab ? post_count : user_post_count) > 0 ? '+' : ''}
                    {selectedTab ? post_count : user_post_count || 0}
                  </>
                ) : (
                  <>
                    {(post_count || user_post_count) > 0 ? '+' : ''}
                    {Organization.isRep() ? user_post_count : post_count}
                  </>
                )}
              </div>
              <div className={styles.name}>{category.name}</div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default TeamPulse;
