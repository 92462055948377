import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { Header, Sidebar } from '../shared_elements';
import AllPaymentAlerts from '../features/payment/components/Alerts/AllPaymentAlerts';
import SmartMentor from 'features/smartMentor/containers/SmartMentor';
import HubspotSettingsModal from 'features/hubspot/settings/HubspotSettingsModal';
import { FeatureFlag } from 'controllers/_exports';
import IntercomIntegration from 'features/intercom/components/IntercomIntegration';
import NewSettingsModal from 'features/tracking/components/NewSettingsModal/NewSettingsModal';
import RepPovBanner from 'features/salesRepView/components/RepPovBanner/RepPovBanner';

// import { ToastMessage, UpdatePopup } from '../shared_elements';
// import { globalGetService } from '../utils/globalApiServices';
/* eslint-disable */
export default function pageLayoutHoc(HocComponent, extraProps = {}) {
  class PageLayout extends Component {
    constructor(props) {
      super(props);
      this.state = {
        location: this.props.location.pathname,
      };
    }
    render() {
      return (
        <>
          <Header />
          <aside>
            <Sidebar />
          </aside>
          <main>
            <div>
              <RepPovBanner aux />
              <AllPaymentAlerts />
              {FeatureFlag.enabled('SMART_MENTOR') && <SmartMentor />}
              <HubspotSettingsModal />
              <NewSettingsModal />
              <IntercomIntegration />
              <HocComponent location={this.state.location} {...this.props} />
            </div>
          </main>
        </>
      );
    }
  }
  return withRouter(PageLayout);
}
