import React, { useMemo } from 'react';
import Organization from 'infrastructure/Organization';
import styles from './RoleplayDashboardList.module.scss';
import { CustomButton } from 'shared_ui_components';
import Filter from 'features/home/components/Filters/Filter';

const AddAssignButton = ({ onClick = () => null, variant = 'outlined' }) => {
  const label = useMemo(() => (Organization.isRep() ? 'Initiate Roleplay' : 'Assign Roleplay'), []);

  if (variant === 'filled')
    return (
      <div className={styles.addBtnFilled}>
        <CustomButton className={`primaryBtn`} onClick={onClick}>
          {label}
        </CustomButton>
        <div className={styles.filter}>
          <span>Period</span>
          <Filter page="roleplay" />
        </div>
      </div>
    );

  return (
    <div onClick={onClick} className={styles.addBtn}>
      <span>+</span>
      <span>{label}</span>
    </div>
  );
};

export default AddAssignButton;
