import { roles } from 'constants';
import { FeatureFlag } from 'controllers/_exports';

const Organization = (function () {
  // Organization info
  const getSelectedOrg = () => JSON.parse(localStorage.getItem('userInfo'))?.selectedOrg;

  const updateOrgStorage = ({ key, value }) => {
    let updatedInfo = JSON.parse(localStorage.getItem('userInfo'));
    updatedInfo.selectedOrg = {
      ...updatedInfo.selectedOrg,
      [key]: value,
    };
    localStorage.setItem('userInfo', JSON.stringify(updatedInfo));
  };

  const ROLES = {
    OWNER: 'primary_admin',
    ADMIN: 'admin',
    LEAD: 'sales_lead',
    REP: 'user',
  };

  // User info
  const check = (role) => getSelectedOrg()?.user_role === roles().find((r) => r.slug === role)?.id;
  const getUserInfo = () => JSON.parse(localStorage.getItem('userInfo'))?.user;

  const isOwner = () => check(ROLES.OWNER);
  const isAdmin = () => check(ROLES.ADMIN);
  const isLead = () => check(ROLES.LEAD);
  const isRep = () => check(ROLES.REP);
  const FiltersEnabled = (selectedSlug) => {
    return FeatureFlag.enabled('FILTERS') ? selectedSlug : 'all-time';
  };
  return {
    getSelectedOrg,
    getUserInfo,
    isAdmin,
    isOwner,
    isLead,
    isRep,
    updateOrgStorage,
    ROLES,
    FiltersEnabled,
  };
})();

export default Organization;
